import * as leasingAssistantConstant from "../constants/leasingAssistantConstant"

const initialState = {
    selectedDealID: null,
    dialogToOpen: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case leasingAssistantConstant.OPEN_CHANGE_STATUS_DIALOG: {
            return Object.assign({}, state, {
                selectedDealID: action.dealID,
                dialogToOpen: leasingAssistantConstant.OPEN_CHANGE_STATUS_DIALOG,
            });
        }
        case leasingAssistantConstant.OPEN_ADD_NOTE_DIALOG: {
            return {
                selectedDealID: action.dealID,
                dialogToOpen: leasingAssistantConstant.OPEN_ADD_NOTE_DIALOG,
            }
        }
        case leasingAssistantConstant.OPEN_DELETE_DIALOG: {
            return {
                selectedDealID: action.dealID,
                dialogToOpen: leasingAssistantConstant.OPEN_DELETE_DIALOG,
            }

        }
        case leasingAssistantConstant.OPEN_EXPORT_DIALOG: {
            return {
                selectedDealID: action.dealID,
                dialogToOpen: leasingAssistantConstant.OPEN_EXPORT_DIALOG,
            }

        }
        case leasingAssistantConstant.CLOSE_DIALOG: {
            return initialState

        }
        default: return state
    }
}

export default reducer;