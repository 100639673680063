import * as dashboardConstants from "../constants/dashboardConstants";
import update from "immutability-helper";

const initialState = {
  dashboardMeta: {
    fetching: false,
    total_buildings: null,
    total_suite_area: null,
    total_suites: null,
    square_feet: 0,
    new_suites: 0,
    new_leases: 0,
    deleted_suites: 0,
    new_leads: 0,
    tours: 0,
    negotiation: 0,
    closed: 0
  },
  distribution: {
    total_area: 0,
    total_suites: "",
    total_percentage: "",
    buckets: []
  },
  leaseExpiryProfile: {
    total_area: "",
    total_suites: "",
    total_percentage: "",
    buckets: []
  },
  filters: {
    cities: [],
    companies: [],
    funds: [],
    groups: [],
    provinces: [],
    regions: [],
    sub_regions: [],
    types: []
  },
  selectedFilters: {
    cities: [],
    company: null,
    fund: null,
    group: null,
    province: null,
    region: null,
    sub_region: null,
    types: []
  },
  staging: {
    cities: [],
    company: null,
    fund: null,
    group: null,
    province: null,
    region: null,
    sub_region: null,
    types: []
  },
  isFiltersApplied: false
};

const reducer = function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.FETCH_DASHBOARD_META:
      return Object.assign({}, state, {
        dashboardMeta: { ...state.dashboardMeta, fetching: true }
      });
    case dashboardConstants.FETCH_DASHBOARD_META_SUCCESSFUL:
      return Object.assign({}, state, {
        dashboardMeta: { ...action.payload, fetching: false }
      });

    case dashboardConstants.FETCH_DASHBOARD_FILTERITEMS_SUCCESSFUL: {
      let cities = action.payload.cities.map(item => {
        return {
          count: item.count,
          id: item.city,
          label: item.city
        };
      });

      let selectedCities =
        !!state.selectedFilters.cities.length || state.isFiltersApplied
          ? state.selectedFilters.cities
          : cities.map(city => city.id);

      let companies = action.payload.company__name.map(item => {
        return {
          value: item.company__name,
          label: item.company__name + ` (${item.count})`
        };
      });

      let funds = action.payload.funds.map(item => {
        return {
          value: item.fund,
          label: item.fund + ` (${item.count})`
        };
      });

      let groups = action.payload.groups.map(item => {
        return {
          value: item.group,
          label: item.group + ` (${item.count})`
        };
      });

      let provinces = action.payload.provinces.map(item => {
        return {
          value: item.province,
          label: item.province + ` (${item.count})`
        };
      });

      let regions = action.payload.regions.map(item => {
        return {
          value: item.region,
          label: item.region + ` (${item.count})`
        };
      });

      let sub_regions = action.payload.sub_regions.map(item => {
        return {
          value: item.sub_region,
          label: item.sub_region + ` (${item.count})`
        };
      });

      let types = action.payload.types.map(item => {
        return {
          id: item[0],
          label: item[1]
        };
      });

      let selectedTypes =
        !!state.selectedFilters.types.length || state.isFiltersApplied
          ? state.selectedFilters.types
          : types.map(type => type.id);

      return update(state, {
        filters: {
          cities: { $set: cities },
          companies: { $set: companies },
          funds: { $set: funds },
          groups: { $set: groups },
          provinces: { $set: provinces },
          regions: { $set: regions },
          sub_regions: { $set: sub_regions },
          types: { $set: types }
        },
        selectedFilters: {
          cities: { $set: [...selectedCities] },
          types: { $set: [...selectedTypes] }
        },
        staging: {
          cities: { $set: [...selectedCities] },
          types: { $set: [...selectedTypes] }
        }
      });
    }

    case dashboardConstants.TOGGLE_FILTER_CHECKBOX: {
      const type = action.payload.type;
      let selectedTypeList = [...state.staging[type]];
      const id = action.payload.id;

      if (id === "-1") {
        selectedTypeList =
          selectedTypeList.length === state.filters[type].length
            ? []
            : state.filters[type].map(item => item.id);
      } else {
        if (selectedTypeList.includes(id)) {
          selectedTypeList = selectedTypeList.filter(item => item !== id);
        } else {
          selectedTypeList.push(id);
        }
      }

      return update(state, {
        staging: {
          [type]: { $set: selectedTypeList }
        }
      });
    }

    case dashboardConstants.SELECT_FILTER_DROPDOWN_ITEM: {
      const val = !!action.payload.id ? action.payload.id.value : null;
      return update(state, {
        staging: {
          [action.payload.type]: { $set: val }
        }
      });
    }

    case dashboardConstants.SAVE_FILTERS: {
      const selectedFilters = {
        cities: [...state.staging.cities],
        company: state.staging.company,
        fund: state.staging.fund,
        group: state.staging.group,
        province: state.staging.province,
        region: state.staging.region,
        sub_region: state.staging.sub_region,
        types: [...state.staging.types]
      };
      return update(state, {
        selectedFilters: { $set: selectedFilters },
        isFiltersApplied: { $set: true }
      });
    }

    case dashboardConstants.RESET_FILTERS: {
      let selectedCities = state.filters.cities.map(city => city.id);
      let selectedTypes = state.filters.types.map(type => type.id);

      return update(state, {
        selectedFilters: {
          cities: { $set: [...selectedCities] },
          company: { $set: null },
          fund: { $set: null },
          group: { $set: null },
          province: { $set: null },
          region: { $set: null },
          sub_region: { $set: null },
          types: { $set: [...selectedTypes] }
        },
        staging: {
          cities: { $set: [...selectedCities] },
          company: { $set: null },
          fund: { $set: null },
          group: { $set: null },
          province: { $set: null },
          region: { $set: null },
          sub_region: { $set: null },
          types: { $set: [...selectedTypes] }
        },
        isFiltersApplied: { $set: false }
      });
    }

    case dashboardConstants.FETCH_DISTRIBUTION_SUCCESSFUL:
      return Object.assign({}, state, {
        distribution: action.payload
      });

    case dashboardConstants.FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_SUCCESSFUL:
      return Object.assign({}, state, {
        leaseExpiryProfile: action.payload
      });

    default:
      return state;
  }
};

export default reducer;
