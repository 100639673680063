import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import Validators from "redux-form-validators";
import { FormattedMessage } from "react-intl";
import Loadable from "react-loadable";

import configureStore from "./store/configureStore";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import "react-select/dist/react-select.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "rc-time-picker/assets/index.css";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./index.css";
import "./print.css";

import App from "./App";
import PrivateRoute from "./components/common/PrivateRoute";
import AppLoader from "./components/common/AppLoader";
import Lang from "./containers/Lang";
import config from "./config";
import ResetPasswordContainer from "./containers/ResetPassword";
import ForcePasswordResetContainer from "./containers/ForcePasswordReset";

import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import { ApolloProvider } from "@apollo/react-hooks";
import fragmentMatcher from "./apollo/apollo-utils/fragmentMatcher";
import AuthService from "./services/authService";

import * as Sentry from "@sentry/browser";

const httpLink = createHttpLink({
  uri: `${config.API_SERVER_BASE_URI}/api/v1/graphql`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = AuthService.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : ""
    }
  };
});

//import registerServiceWorker from './registerServiceWorker';
// Initialize ApolloClient

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher })
});

// Initialize google analytics lib
ReactGA.initialize(config.GOOGLE_ANALYTICS_KEY, {
  debug: false
});

// Internationalization for Redux Form Validator Messages
Validators.formatMessage = function(msg) {
  return <FormattedMessage {...(msg.props || msg)} />;
};

Object.assign(Validators.messages, {
  notANumber: {
    id: "form.validators.errors.notANumber",
    defaultMessage: "is not a number"
  },
  notAnInteger: {
    id: "form.validators.errors.notAnInteger",
    defaultMessage: "is not an integer"
  }
});

const LoginContainerLazy = Loadable({
  loader: () => import("./containers/Login"),
  loading: AppLoader
});

const InitContainerLazy = Loadable({
  loader: () => import("./containers/Init"),
  loading: AppLoader
});
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: config.SENTRY_DSN
  });
}
const store = configureStore(); //can pass in an initial state here
ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Lang>
        <Router>
          <App>
            <Switch>
              <Route path="/login" component={LoginContainerLazy} />
              <Route
                path="/reset-password"
                component={ResetPasswordContainer}
              />
              <PrivateRoute
                path="/force-password-change"
                component={ForcePasswordResetContainer}
              />
              <PrivateRoute path="/" component={InitContainerLazy} />
            </Switch>
            <ToastContainer
              position="top-center"
              type="default"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              pauseOnHover
              className="arcestra-toast-container"
              toastClassName="arcestra-toast"
              progressClassName="arcestra-toast-progress"
            />
          </App>
        </Router>
      </Lang>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

//registerServiceWorker();
