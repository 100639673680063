import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import qs from "query-string";
import { Button, Card, CardBody, Form, FormGroup, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

import InputField from "../../components/form/Input";
import {
  validateResetPasswordKey,
  resetPasswordKeyAbsent,
  resetPassword,
  resetPasswordPageUnmount
} from "../Auth/actions";
import * as authConstants from "../Auth/constants";

import "./styles.css";
import InformationToolTip from "../../components/common/InformationToolTip";

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isResetPasswordSuccessful: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResetPasswordSuccess = this.onResetPasswordSuccess.bind(this);
    this.onResetPasswordError = this.onResetPasswordError.bind(this);
  }

  componentDidMount() {
    const search = this.props.location.search;
    this.key = !!search ? qs.parse(search).key : null;

    !!this.key
      ? this.props.actions.validateResetPasswordKey({ key: this.key })
      : this.props.actions.resetPasswordKeyAbsent();
  }

  componentWillUnmount() {
    this.props.actions.resetPasswordPageUnmount();
  }

  onResetPasswordSuccess() {
    ReactGA.event({
      category: "Authentication",
      action: "Password reset successful"
    });
    this.setState({
      isResetPasswordSuccessful: true
    });
  }

  onResetPasswordError() {
    ReactGA.event({
      category: "Authentication",
      action: "Password reset failed"
    });
    toast("Unable to reset your password. Please try again later.", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  onSubmit(values) {
    values.key = this.key;

    const payload = {
      request: values,
      onSuccess: this.onResetPasswordSuccess,
      onError: this.onResetPasswordError
    };

    this.props.actions.resetPassword(payload);
  }

  onCancel() {
    this.props.history.replace(`/login`);
    //window.location.href = '/login';
  }

  render() {
    const { resetPasswordKeyState, handleSubmit, errors } = this.props;
    return (
      <div className="login-page reset-password-page row">
        <div className="login-container">
          <div className="cover-container">
            <div className="cover-overlay" />
            <div className="brand-container" />
          </div>

          <div className="login-form-container reset-password-container">
            {this.state.isResetPasswordSuccessful ? (
              <div className="info-container">
                Password reset successfully!
                <br />
                Click{" "}
                <Link
                  to={{
                    pathname: "/login"
                  }}
                  replace={true}
                >
                  here
                </Link>{" "}
                to login to Arcestra
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {resetPasswordKeyState ===
                  authConstants.resetPasswordKeyState.NOT_SUPPLIED && (
                  <div className="info-container">
                    Invalid reset password link.
                  </div>
                )}
                {resetPasswordKeyState ===
                  authConstants.resetPasswordKeyState.SUPPLIED && (
                  <div>
                    <Spinner name="line-scale-pulse-out" color="#39aabb" />
                  </div>
                )}
                {resetPasswordKeyState ===
                  authConstants.resetPasswordKeyState.INVALID && (
                  <div className="info-container">
                    The reset password link has expired.
                    <br />
                    Please click{" "}
                    <Link
                      to={{
                        pathname: "/login",
                        state: { forgotPassword: true }
                      }}
                      replace={true}
                    >
                      here
                    </Link>{" "}
                    to reset your password again.
                  </div>
                )}
                {resetPasswordKeyState ===
                  authConstants.resetPasswordKeyState.VALID && (
                  <Card className="col col-sm-8">
                    <CardBody>
                      {errors && errors.code === "bad_password" && (
                        <Alert color="danger">
                          <FormattedMessage
                            defaultMessage="Password must contain at least 1 special character, 1 number, 1 capital letter and have a minimum length of 10 characters"
                            id="common.form.validation.password-rules"
                          />
                        </Alert>
                      )}
                      <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <FormGroup>
                          <Field
                            name="password_one"
                            type="password"
                            component={InputField}
                            autoFocus
                          />
                          <label>
                            <span>
                              <FormattedMessage
                                id="reset-password.input.new-password.label"
                                defaultMessage="New Password"
                              />
                              <InformationToolTip
                                messageID={
                                  "common.form.validation.password-rules"
                                }
                                defaultMessage={
                                  "Password must contain at least 1 special character, 1 number, 1 capital letter and have a minimum length of 10 characters."
                                }
                              />
                            </span>
                            &nbsp;|
                          </label>
                        </FormGroup>
                        <FormGroup>
                          <Field
                            name="password_two"
                            type="password"
                            component={InputField}
                          />
                          <label>
                            <FormattedMessage
                              id="reset-password.input.confirm-password.label"
                              defaultMessage="Confirm Password"
                            />
                            &nbsp;|
                          </label>
                        </FormGroup>

                        <div className="buttons-container">
                          <Button color="primary" onClick={this.onCancel}>
                            <FormattedMessage
                              id="common.button.cancel.label"
                              defaultMessage="Cancel"
                            />
                          </Button>
                          <Button type="submit">
                            <FormattedMessage
                              id="reset-password.btn.reset.label"
                              defaultMessage="Reset Password"
                            />
                          </Button>
                        </div>
                      </Form>
                      <div className="error-container" />
                    </CardBody>
                  </Card>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    resetPasswordKeyState: state.auth.resetPasswordKeyState,
    errors: state.auth.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        validateResetPasswordKey: validateResetPasswordKey,
        resetPasswordKeyAbsent: resetPasswordKeyAbsent,
        resetPassword: resetPassword,
        resetPasswordPageUnmount: resetPasswordPageUnmount
      },
      dispatch
    )
  };
}

const validate = values => {
  const errors = {};
  if (!values.password1) {
    errors.password1 = "Please enter the new password";
  }
  if (!values.password2) {
    errors.password2 = "Please enter the new password again";
  }
  if (values.password2) {
    if (!values.password1 || values.password1 !== values.password2) {
      errors.password2 = "This does not match the new password entered above ";
    }
  }
  return errors;
};

const formed = reduxForm({
  form: "reset-password",
  enableReinitialize: true,
  validate: validate
})(ResetPasswordContainer);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(formed)
);
