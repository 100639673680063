import axios from "axios";
import config from "../config";

const http = axios.create({
  baseURL: config.API_SERVER_BASE_URI
});

export const noAuthHttp = axios.create({
  baseURL: config.API_SERVER_BASE_URI
});

export const noBaseHttp = axios.create();

export default http;
