import * as buildingReportsConstants from "../constants/buildingReportsConstants";

const initialState = {
  distributionReport: {
    total_area: 0,
    total_suites: "",
    total_percentage: "",
    buckets: []
  },
  leaseExpiryProfileReport: {
    total_area: "",
    total_suites: "",
    total_percentage: "",
    buckets: []
  },
  occupancyReport: [],
  largestTenantReport: {
    buckets: [],
    total_area: "",
    total_rentable_area: "",
    total_suites: ""
  },
  vacantSuitesReport: {
    total_vacant_suites: "",
    vacant_suites: []
  }
};

const reducer = function buildingReportsReducer(state = initialState, action) {
  switch (action.type) {
    case buildingReportsConstants.FETCH_OCCUPANCY_REPORT_SUCCESSFUL:
      return Object.assign({}, state, {
        occupancyReport: action.payload
      });

    case buildingReportsConstants.FETCH_DISTRIBUTION_REPORT_SUCCESSFUL:
      return Object.assign({}, state, {
        distributionReport: action.payload
      });

    case buildingReportsConstants.FETCH_LEASE_EXPIRY_PROFILE_REPORT_SUCCESSFUL:
      return Object.assign({}, state, {
        leaseExpiryProfileReport: action.payload
      });

    case buildingReportsConstants.FETCH_LARGEST_TENANT_REPORT_SUCCESSFUL:
      return Object.assign({}, state, {
        largestTenantReport: action.payload
      });

    case buildingReportsConstants.FETCH_VACANT_SUITES_REPORT_SUCCESSFUL:
      return Object.assign({}, state, {
        vacantSuitesReport: action.payload
      });

    case buildingReportsConstants.BUILDING_REPORTS_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
