import * as appConstants from "../constants/appConstants";

const initialState = {
  printModeEnabled: false,

  systemMessage: {
    enabled: false,
    english: undefined,
    french: undefined
  }
};

const reducer = function appReducer(state = initialState, action) {
  switch (action.type) {
    case appConstants.ENABLE_PRINT_MODE:
      return Object.assign({}, state, {
        printModeEnabled: true
      });

    case appConstants.SYSTEM_SETTINGS_SUCCESSFUL:
      const systemSettings = action.payload;
      const systemMessage = {
        enabled: systemSettings.SYSTEM_MESSAGE_ENABLED || false,
        english: systemSettings.SYSTEM_MESSAGE_ENGLISH,
        french: systemSettings.SYSTEM_MESSAGE_FRENCH
      };
      return Object.assign({}, state, {
        systemMessage: systemMessage
      });

    default:
      return state;
  }
};

export default reducer;
