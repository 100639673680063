import { takeEvery, call, put } from "redux-saga/effects";

import * as stackingPlanConstants from "../constants/stackingPlanConstants";
import StackingPlanService from "../services/stackingPlanService";

function* fetchStackingPlan(action) {
  try {
    const stackingPlan = yield call(
      StackingPlanService.fetchStackingPlan,
      action.payload
    );
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_SUCCESSFUL,
      payload: stackingPlan
    });
  } catch (error) {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FAILED,
      error
    });
  }
}

function* fetchStackingPlanFilterProfile(action) {
  try {
    const result = yield call(
      StackingPlanService.fetchStackingPlanFilterProfile,
      action.payload
    );
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE_SUCCESSFUL,
      payload: {
        ...result,
        filtertype: action.payload.filtertype
      }
    });
  } catch (error) {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE_FAILED,
      error
    });
  }
}

export function* fetchStackingPlanWatcher() {
  yield takeEvery(stackingPlanConstants.FETCH_STACKING_PLAN, fetchStackingPlan);
}

export function* fetchStackingPlanFilterProfileWatcher() {
  yield takeEvery(
    stackingPlanConstants.FETCH_STACKING_PLAN_FILTERPROFILE,
    fetchStackingPlanFilterProfile
  );
}
