import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  CardTitle,
  Alert
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

import InputField from "../../components/form/Input";
import {
  resetPasswordPageUnmount,
  logout,
  forcePasswordChange
} from "../Auth/actions";
import * as authConstants from "../Auth/constants";

import "../ResetPassword/styles.css";
import "../Login/styles.css";
import authService from "../../services/authService";
import brandImg from "../../assets/images/findspace-logo-reverse.png";
import InformationToolTip from "../../components/common/InformationToolTip";

class ForceResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetPasswordSuccessful: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResetPasswordSuccess = this.onResetPasswordSuccess.bind(this);
    this.onResetPasswordError = this.onResetPasswordError.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
    this.onLogoutError = this.onLogoutError.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetPasswordPageUnmount();
  }

  onResetPasswordSuccess() {
    ReactGA.event({
      category: "Authentication",
      action: "Password reset successful"
    });
    this.setState({
      isResetPasswordSuccessful: true
    });
  }

  onResetPasswordError() {
    ReactGA.event({
      category: "Authentication",
      action: "Password reset failed"
    });
    this.setState({
      isResetPasswordSuccessful: false
    });
    toast("Unable to reset your password. Please try again later.", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  onSubmit(values) {
    const payload = {
      request: values,
      onSuccess: this.onResetPasswordSuccess,
      onError: this.onResetPasswordError
    };

    this.props.actions.forcePasswordChange(payload);
  }

  onLogoutError() {
    toast("Unable to logout. Please try again later.", {
      position: toast.POSITION.TOP_CENTER
    });
  }
  onLogoutSuccess() {
    this.props.history.replace(`/login`);
  }

  onCancel() {
    this.props.actions.logout({
      onSuccess: this.onLogoutSuccess,
      onError: this.onLogoutError
    });
  }

  render() {
    const { handleSubmit, errors } = this.props;
    return (
      <div className="login-page reset-password-page row">
        <div className="login-container">
          <div className="cover-container">
            <div className="cover-overlay" />
            <div className="brand-container">
              <img src={brandImg} />
            </div>
          </div>

          <div className="login-form-container reset-password-container">
            {this.state.isResetPasswordSuccessful ? (
              <div className="info-container">
                <FormattedMessage
                  id="reset-password.password-reset-success"
                  defaultMessage="Password reset successfully!"
                />
                <br />
                <FormattedMessage
                  id="reset-password.go-to-main-site"
                  values={{
                    settings: (
                      <Link
                        to={{
                          pathname: "/login"
                        }}
                        replace={true}
                      >
                        <FormattedMessage
                          id="reset-password.here"
                          defaultMessage="here"
                        />
                      </Link>
                    )
                  }}
                  defaultMessage="Click {settings} to go to the main site!"
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Card className="col col-sm-8">
                  <CardTitle style={{ margin: "10px 20px" }} tag="h5">
                    <FormattedMessage
                      id="reset-password.infotext"
                      defaultMessage="Please reset your password before continuing."
                    />
                  </CardTitle>
                  <CardBody>
                    {errors && errors.code === "bad_password" && (
                      <Alert color="danger">
                        <FormattedMessage
                          defaultMessage="Password must contain at least 1 special character, 1 number, 1 capital letter and have a minimum length of 10 characters"
                          id="common.form.validation.password-rules"
                        />
                      </Alert>
                    )}
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                      <FormGroup>
                        <Field
                          name="password_one"
                          type="password"
                          component={InputField}
                          autoFocus
                        />
                        <label>
                          <span>
                            <FormattedMessage
                              id="reset-password.input.new-password.label"
                              defaultMessage="New Password"
                            />
                            <InformationToolTip
                              messageID={
                                "common.form.validation.password-rules"
                              }
                              defaultMessage={
                                "Password must contain at least 1 special character, 1 number, 1 capital letter and have a minimum length of 10 characters."
                              }
                            />
                          </span>
                          &nbsp;|
                        </label>
                      </FormGroup>
                      <FormGroup>
                        <Field
                          name="password_two"
                          type="password"
                          component={InputField}
                        />
                        <label>
                          <FormattedMessage
                            id="reset-password.input.confirm-password.label"
                            defaultMessage="Confirm Password"
                          />
                          &nbsp;|
                        </label>
                      </FormGroup>

                      <div className="buttons-container">
                        <Button color="primary" onClick={this.onCancel}>
                          <FormattedMessage
                            id="common.button.cancel.label"
                            defaultMessage="Cancel"
                          />
                        </Button>
                        <Button type="submit" disabled={!this.props.valid}>
                          <FormattedMessage
                            id="reset-password.btn.reset.label"
                            defaultMessage="Reset Password"
                          />
                        </Button>
                      </div>
                    </Form>
                    <div className="error-container" />
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.auth.errors };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        forcePasswordChange: forcePasswordChange,
        resetPasswordPageUnmount: resetPasswordPageUnmount,
        logout: logout
      },
      dispatch
    )
  };
}

const validate = (values, { intl }) => {
  const errors = {};
  if (!values.password_one) {
    errors.password_one = intl.formatMessage({
      id: "reset-password.error.no_password_one",
      defaultMessage: "Please enter a new password"
    });
  }
  if (!values.password_two) {
    errors.password_two = intl.formatMessage({
      id: "reset-password.error.no_password_two",
      defaultMessage: "Please confirm your new password"
    });
  }
  if (values.password_two) {
    if (!values.password_one || values.password_one !== values.password_two) {
      errors.password_two = intl.formatMessage({
        id: "reset-password.error.password_mismatch",
        defaultMessage: "Passwords do not match"
      });
    }
  }
  return errors;
};

const formed = injectIntl(
  reduxForm({
    form: "force-password-change",
    enableReinitialize: true,
    validate: validate
  })(ForceResetPasswordContainer)
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(formed)
);
