export const FETCH_USER = "User/FETCH_USER";
export const FETCH_USER_SUCCESSFUL = "User/FETCH_USER_SUCCESSFUL";
export const FETCH_USER_FAILED = "User/FETCH_USER_FAILED";

export const CREATE_USER = "User/CREATE_USER";
export const CREATE_USER_SUCCESSFUL = "User/CREATE_USER_SUCCESSFUL";
export const CREATE_USER_FAILED = "User/CREATE_USER_FAILED";

export const EDIT_USER = "User/EDIT_USER";
export const EDIT_USER_SUCCESSFUL = "User/EDIT_USER_SUCCESSFUL";
export const EDIT_USER_FAILED = "User/EDIT_USER_FAILED";

export const DELETE_USER = "User/DELETE_USER";
export const DELETE_USER_SUCCESSFUL = "User/DELETE_USER_SUCCESSFUL";
export const DELETE_USER_FAILED = "User/DELETE_USER_FAILED";

export const CHANGE_PASSWORD = "Account/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESSFUL = "Account/CHANGE_PASSWORD_SUCCESSFUL";
export const CHANGE_PASSWORD_FAILED = "Account/CHANGE_PASSWORD_FAILED";

export const FETCH_USERS = "User/FETCH_USERS";
export const FETCH_USERS_SUCCESSFUL = "User/FETCH_USERS_SUCCESSFUL";
export const FETCH_USERS_FAILED = "User/FETCH_USERS_FAILED";

export const FETCH_USER_DETAILS = "User/FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESSFUL =
  "User/FETCH_USER_DETAILS_SUCCESSFUL";
export const FETCH_USER_DETAILS_FAILED = "User/FETCH_USER_DETAILS_FAILED";

export const EDIT_USER_DETAILS = "User/EDIT_USER_DETAILS";
export const EDIT_USER_DETAILS_SUCCESSFUL = "User/EDIT_USER_DETAILS_SUCCESSFUL";
export const EDIT_USER_DETAILS_FAILED = "User/EDIT_USER_DETAILS_FAILED";

export const FETCH_USER_BUILDINGS = "User/FETCH_USER_BUILDINGS";
export const FETCH_USER_BUILDINGS_SUCCESSFUL =
  "User/FETCH_USER_BUILDINGS_SUCCESSFUL";
export const FETCH_USER_BUILDINGS_FAILED = "User/FETCH_USER_BUILDINGS_FAILED";

export const DELETE_USER_BUILDING = "User/DELETE_USER_BUILDING";
export const DELETE_USER_BUILDING_SUCCESSFUL =
  "User/DELETE_USER_BUILDING_SUCCESSFUL";
export const DELETE_USER_BUILDING_FAILED = "User/DELETE_USER_BUILDING_FAILED";

export const EDIT_USER_ROLE_TYPE = "User/EDIT_USER_ROLE_TYPE";
export const EDIT_USER_ROLE_TYPE_SUCCESSFUL =
  "User/EDIT_USER_ROLE_TYPE_SUCCESSFUL";
export const EDIT_USER_ROLE_TYPE_FAILED = "User/EDIT_USER_ROLE_TYPE_FAILED";

// For New User Creation
export const FETCH_ALL_BUILDINGS = "User/FETCH_ALL_BUILDINGS";
export const FETCH_ALL_BUILDINGS_SUCCESSFUL =
  "User/FETCH_ALL_BUILDINGS_SUCCESSFUL";
export const FETCH_ALL_BUILDINGS_FAILED = "User/FETCH_ALL_BUILDINGS_FAILED";

// For transfer of building from one user to another
export const TRANSFER_BUILDING_ASSIGNMENTS =
  "User/TRANSFER_BUILDING_ASSIGNMENTS";
export const TRANSFER_BUILDING_ASSIGNMENTS_SUCCESSFUL =
  "User/TRANSFER_BUILDING_ASSIGNMENTS_SUCCESSFUL";
export const TRANSFER_BUILDING_ASSIGNMENTS_FAILED =
  "User/TRANSFER_BUILDING_ASSIGNMENTS_FAILED";

export const COPY_BUILDING_ASSIGNMENTS = "User/COPY_BUILDING_ASSIGNMENTS";
export const COPY_BUILDING_ASSIGNMENTS_SUCCESSFUL =
  "User/COPY_BUILDING_ASSIGNMENTS_SUCCESSFUL";
export const COPY_BUILDING_ASSIGNMENTS_FAILED =
  "User/COPY_BUILDING_ASSIGNMENTS_FAILED";

export const portfolioManagementMode = {
  TRANSFER: "TRANSFER",
  COPY: "COPY"
};

export const ADD_USER_BUILDINGS = "User/ADD_USER_BUILDINGS";
export const ADD_USER_BUILDINGS_SUCCESSFUL =
  "User/ADD_USER_BUILDINGS_SUCCESSFUL";
export const ADD_USER_BUILDINGS_FAILED = "User/ADD_USER_BUILDINGS_FAILED";
