import { takeEvery, call, put } from "redux-saga/effects";
import * as actionTypes from "./constants";
import AuthService from "../../services/authService";

function* verifyToken(action) {
  try {
    const token = yield call(AuthService.verifyToken);
    yield put({ type: actionTypes.TOKEN_VERIFICATION_SUCCESSFUL });
  } catch (error) {
    if (error !== AuthService.forcePasswordChangeResponse) {
      yield put({ type: actionTypes.TOKEN_VERIFICATION_FAILED, error });
      yield put({ type: actionTypes.LOGOUT_USER, error });
    }
  }
}

function* refreshToken(action) {
  try {
    const token = yield call(AuthService.refreshToken);
    yield put({ type: actionTypes.TOKEN_REFRESH_SUCCESSFUL, payload: token });
  } catch (error) {
    yield put({ type: actionTypes.TOKEN_REFRESH_FAILED, error });
    yield put({ type: actionTypes.LOGOUT_USER, error });
  }
}

function* authenticateUser(action) {
  const { username, password } = action.payload;
  try {
    const token = yield call(AuthService.login, username, password);
    yield put({ type: actionTypes.AUTHENTICATION_SUCCESSFUL, payload: token });
  } catch (error) {
    yield put({ type: actionTypes.AUTHENTICATION_FAILED, error });
  }
}

function* logout(action) {
  const { onSuccess, onError } = action.payload;
  try {
    yield call(AuthService.logout);
    yield put({ type: actionTypes.LOGOUT_SUCCESSFUL });
    yield put({ type: actionTypes.CLEAR_APP_STATE });
    onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.LOGOUT_FAILED, error });
    onError();
  }
}

function* forgotPassword(action) {
  try {
    yield call(AuthService.forgotPassword, action.payload.request);
    action.payload.onSuccess();
  } catch (error) {
    action.payload.onError(error);
  }
}

function* validateResetPasswordKey(action) {
  try {
    const result = yield call(
      AuthService.validateResetPasswordKey,
      action.payload
    );
    yield put({
      type: actionTypes.VALIDATE_RESET_PASSWORD_KEY_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: actionTypes.VALIDATE_RESET_PASSWORD_KEY_FAILED, error });
  }
}

function* resetPassword(action) {
  const { request, onSuccess, onError } = action.payload;
  try {
    const result = yield call(AuthService.resetPassword, request);
    yield put({ type: actionTypes.RESET_PASSWORD_SUCCESSFUL, payload: result });
    onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.RESET_PASSWORD_FAILED, error });
    onError();
  }
}

function* forcePasswordChange(action) {
  const { request, onSuccess, onError } = action.payload;
  try {
    const result = yield call(AuthService.forcePasswordChange, request);
    yield put({
      type: actionTypes.FORCE_PASSWORD_CHANGE_SUCCESSFUL,
      payload: result
    });
    onSuccess();
  } catch (error) {
    yield put({ type: actionTypes.FORCE_PASSWORD_CHANGE_FAILED, error });
    onError();
  }
}

/**
 * Watcher function exports
 */

export function* refreshTokenWatcher() {
  yield takeEvery(actionTypes.REFRESH_TOKEN, refreshToken);
}

export function* verifyTokenWatcher() {
  yield takeEvery(actionTypes.VERIFY_TOKEN, verifyToken);
}

export function* tokenVerificationFailedWatcher() {
  //yield takeEvery(actionTypes.TOKEN_VERIFICATION_FAILED, logoutUser)
  //yield takeEvery("FFFF", logout)
}

export function* authenticateUserWatcher() {
  yield takeEvery(actionTypes.AUTHENTICATE_USER, authenticateUser);
}

export function* logoutWatcher() {
  yield takeEvery(actionTypes.LOGOUT_USER, logout);
}

export function* forgotPasswordWatcher() {
  yield takeEvery(actionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* validateResetPasswordKeyWatcher() {
  yield takeEvery(
    actionTypes.VALIDATE_RESET_PASSWORD_KEY,
    validateResetPasswordKey
  );
}

export function* resetPasswordWatcher() {
  yield takeEvery(actionTypes.RESET_PASSWORD, resetPassword);
}

export function* forcePasswordChangeWatcher() {
  yield takeEvery(actionTypes.FORCE_PASSWORD_CHANGE, forcePasswordChange);
}
