import React, { Component } from "react";
import { Input, FormGroup, InputGroup, InputGroupAddon } from "reactstrap";
import { PropTypes } from "prop-types";
import ReactTooltip from "react-tooltip";

export default class InputField extends Component {
  render() {
    const {
      input,
      placeholder,
      type,
      currency,
      meta: { touched, error, dirty, warning },
      showErrorInfoIcon,
      ...rest
    } = this.props;

    let classes = touched && !error && dirty ? "success" : "";
    classes = classes + (touched && error ? "danger" : "");
    //{touched && error && <FormFeedback>{error}</FormFeedback>}
    return (
      <FormGroup color={classes}>
        {currency ? (
          <InputGroup>
            <InputGroupAddon addontype="prepend">$</InputGroupAddon>
            <Input
              {...input}
              type={type}
              placeholder={placeholder}
              state={classes}
              {...rest}
            />
          </InputGroup>
        ) : (
          <Input
            style={{ height: "auto" }}
            {...input}
            type={type}
            placeholder={placeholder}
            state={classes}
            {...rest}
          />
        )}

        {showErrorInfoIcon &&
          touched &&
          (error || warning) && [
            <i
              key={1}
              data-tip
              data-for={`tooltip-info-${input.name}`}
              className={
                "fa fa-exclamation-circle" +
                (touched && ((error && " error") || (warning && " warning")))
              }
              aria-hidden="true"
            />,
            <ReactTooltip
              key={2}
              id={`tooltip-info-${input.name}`}
              type={error ? "error" : "warning"}
              effect="solid"
              place="right"
            >
              <span>{error ? error : warning}</span>
            </ReactTooltip>
          ]}
        {!showErrorInfoIcon &&
          touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span className="warning">{warning}</span>))}
      </FormGroup>
    );
  }
}

InputField.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any
  })
};
