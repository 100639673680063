import { takeEvery, call, put } from "redux-saga/effects";

import * as rentRollConstants from "../constants/rentRollConstants";
import RentRollService from "../services/rentRollService";

function* fetchRentRoll(action) {
  try {
    const rentRoll = yield call(RentRollService.fetchRentRoll, action.payload);
    yield put({
      type: rentRollConstants.FETCH_RENT_ROLL_SUCCESSFUL,
      payload: rentRoll
    });
  } catch (error) {
    yield put({ type: rentRollConstants.FETCH_RENT_ROLL_FAILED, error });
  }
}

export function* fetchRentRollWatcher() {
  yield takeEvery(rentRollConstants.FETCH_RENT_ROLL, fetchRentRoll);
}
