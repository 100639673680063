import React from "react";
import { FormattedMessage } from "react-intl";

const label = props => <FormattedMessage {...props} />;

export const units = {
  SQ_FT: label({ id: "common.units.sq-ft", defaultMessage: "sq. ft." }),
  SQ_M: label({ id: "common.units.sq-m", defaultMessage: "sq. m." }),
  DOLLAR: label({ id: "common.units.dollar", defaultMessage: "$" }),
  DOLLAR_PER_SQ_FT: label({
    id: "common.units.dollar-per-sq-ft",
    defaultMessage: "$/sq. ft."
  }),
  DOLLAR_PER_HOUR: label({
    id: "common.units.dollar-per-hour",
    defaultMessage: "$/hr"
  }),
  DOLLAR_PER_DAY: label({
    id: "common.units.dollar-per-day",
    defaultMessage: "$/day"
  }),
  DOLLAR_PER_MONTH: label({
    id: "common.units.dollar-per-month",
    defaultMessage: "$/month"
  }),
  NUM_PER_SQ_FT: label({
    id: "common.units.num-per-sq-ft",
    defaultMessage: "#/sq. ft."
  }),
  VOLT: label({ id: "common.units.voltage", defaultMessage: "V" }),
  AMPERE: label({ id: "common.units.amperage", defaultMessage: "A" }),
  WATT: label({ id: "common.units.watt", defaultMessage: "W" }),
  WATT_PER_SQ_FT: label({
    id: "common.units.watt-per-sq-ft",
    defaultMessage: "W/sq. ft."
  }),
  PERCENT: label({ id: "common.units.percentage", defaultMessage: "%" }),
  ACRES: label({ id: "common.units.acres", defaultMessage: "acres" }),
  DOLLAR_PER_ACRE: label({
    id: "common.units.dollar-per-acre",
    defaultMessage: "$/acre"
  }),
  FEET: label({ id: "common.units.feet", defaultMessage: "ft." }),
  INCHES: label({ id: "common.units.inches", defaultMessage: "in." }),
  FEET_INCHES: label({ id: "common.units.ft-in", defaultMessage: "ft. in." }),
  YEARS: label({ id: "common.units.years", defaultMessage: "years" })
};

export const buttons = {
  UPDATE: label({ id: "common.button.update.label", defaultMessage: "Update" }),
  CANCEL: label({ id: "common.button.cancel.label", defaultMessage: "Cancel" }),
  SAVE: label({ id: "common.button.save.label", defaultMessage: "Save" }),
  EDIT: label({ id: "common.button.edit.label", defaultMessage: "Edit" }),
  DELETE: label({ id: "common.button.delete.label", defaultMessage: "Delete" }),
  REMOVE: label({ id: "common.button.remove.label", defaultMessage: "Remove" }),
  RESET: label({ id: "common.button.reset.label", defaultMessage: "Reset" }),
  NEXT: label({ id: "common.button.next.label", defaultMessage: "Next" }),
  FILTER: label({ id: "common.button.filter.label", defaultMessage: "Filter" }),
  YES: label({ id: "common.button.yes.label", defaultMessage: "Yes" }),
  NO: label({ id: "common.button.no.label", defaultMessage: "No" }),
  ADD: label({ id: "common.button.add.label", defaultMessage: "Add" }),
  TRANSFER: label({
    id: "common.button.transfer.label",
    defaultMessage: "Transfer"
  }),
  COPY: label({ id: "common.button.copy.label", defaultMessage: "Copy" }),
  ADD_USER: label({
    id: "common.button.add-user.label",
    defaultMessage: "Add User"
  }),
  TRANSFER_BUILDINGS: label({
    id: "common.button.transfer-buildings.label",
    defaultMessage: "Transfer Buildings"
  }),
  COPY_BUILDINGS: label({
    id: "common.button.copy-buildings.label",
    defaultMessage: "Copy Buildings"
  }),
  CREATE_USER: label({
    id: "common.button.create-user.label",
    defaultMessage: "Create User"
  }),
  ADD_BUILDINGS: label({
    id: "common.button.add-buildings.label",
    defaultMessage: "Add Buildings"
  }),
  UPLOAD: label({ id: "common.button.upload.label", defaultMessage: "Upload" }),
  CLOSE: label({ id: "common.button.close.label", defaultMessage: "Close" }),
  NEW: label({ id: "common.button.new.label", defaultMessage: "New" })
};
