import * as authConstants from "./constants";

export function verifyToken(payload) {
  return {
    type: authConstants.VERIFY_TOKEN,
    payload
  };
}

export function refreshToken(payload) {
  return {
    type: authConstants.REFRESH_TOKEN,
    payload
  };
}

export function authenticateUser(payload) {
  return {
    type: authConstants.AUTHENTICATE_USER,
    payload
  };
}

export function logout(payload) {
  return {
    type: authConstants.LOGOUT_USER,
    payload
  };
}

export function forgotPassword(payload) {
  return {
    type: authConstants.FORGOT_PASSWORD,
    payload
  };
}

export function validateResetPasswordKey(payload) {
  return {
    type: authConstants.VALIDATE_RESET_PASSWORD_KEY,
    payload
  };
}

export function resetPasswordKeyAbsent(payload) {
  return {
    type: authConstants.RESET_PASSWORD_KEY_NOT_SUPPLIED,
    payload
  };
}

export function resetPassword(payload) {
  return {
    type: authConstants.RESET_PASSWORD,
    payload
  };
}

export function resetPasswordPageUnmount(payload) {
  return {
    type: authConstants.RESET_PASSWORD_PAGE_UNMOUNT,
    payload
  };
}

export function forcePasswordChange(payload) {
  return {
    type: authConstants.FORCE_PASSWORD_CHANGE,
    payload
  };
}
