import * as buildingInformationConstants from "../constants/buildingInformationConstants";

const initialState = {
  buildingInformationList: [],
  meta: {
    "18mo_roll": "",
    "18mo_roll_sqft": "",
    available: "",
    available_sqft: "",
    avgrent: "",
    floors: "",
    leases: "",
    occupancy: "",
    occupancy_sqft: "",
    rba: "",
    suites: "",
    wale: ""
  }
};

const reducer = function buildingInformationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case buildingInformationConstants.FETCH_BUILDING_INFO_LIST_SUCCESSFUL:
      return Object.assign({}, state, {
        buildingInformationList: action.payload
      });

    case buildingInformationConstants.FETCH_BUILDING_INFO_META_SUCCESSFUL:
      return Object.assign({}, state, {
        meta: action.payload
      });

    case buildingInformationConstants.BUILDING_INFORMATION_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
