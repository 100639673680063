import http from "../auth/http";

function getFiltersQueryString(filters) {
  var queryString = "";
  if (!!filters) {
    if (!!filters.cities && filters.cities.length) {
      queryString = queryString.concat(
        "&city=" +
          filters.cities.map(city => encodeURIComponent(city)).join(",")
      );
    }

    if (!!filters.buildingTypes && filters.buildingTypes.length) {
      queryString = queryString.concat(
        "&_type=" +
          filters.buildingTypes.map(type => encodeURIComponent(type)).join(",")
      );
    }

    if (!!filters.pubStatus && filters.pubStatus.length) {
      queryString = queryString.concat(
        "&published=" +
          filters.pubStatus
            .map(status => {
              return status === "P" ? "true" : "false";
            })
            .join(",")
      );
    }

    if (!!filters.favStatus && filters.favStatus.length) {
      queryString = queryString.concat(
        "&favourite=" +
          filters.favStatus
            .map(status => {
              return status === "F" ? "true" : "false";
            })
            .join(",")
      );
    }

    if (!!filters.archivedStatus && filters.archivedStatus.length) {
      queryString = queryString.concat(
        "&archived=" +
          filters.archivedStatus
            .map(status => {
              return status === "A" ? "true" : "false";
            })
            .join(",")
      );
    }

    !!filters.company &&
      (queryString = queryString.concat(
        "&company__name=" + encodeURIComponent(filters.company)
      ));
    !!filters.fund &&
      (queryString = queryString.concat(
        "&fund=" + encodeURIComponent(filters.fund)
      ));
    !!filters.group &&
      (queryString = queryString.concat(
        "&group=" + encodeURIComponent(filters.group)
      ));

    // Area range filters
    !!filters.area_max &&
      (queryString = queryString.concat("&area__lte=" + filters.area_max));
    !!filters.area_min &&
      (queryString = queryString.concat("&area__gte=" + filters.area_min));

    if (!!filters.search) {
      queryString = queryString.concat("&search=" + filters.search);
    }
  }

  return queryString;
}

const BuildingsService = () => {
  const fetchBuilding = request =>
    http
      .get(`api/v1/buildings/${request.id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchBuildings = request => {
    let { page, ordering } = request;
    page = page || 1;

    let url = `api/v1/buildings/?page=${page}`;
    url = ordering ? url.concat(`&ordering=${ordering}`) : url;

    let filtersQueryString = getFiltersQueryString(request.filters);
    url = !!filtersQueryString ? url.concat(filtersQueryString) : url;

    return http
      .get(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));
  };

  const updateBuilding = request =>
    http
      .patch(`api/v1/buildings/${request.buildingId}/`, request.building)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const setFavourite = request =>
    http
      .patch(`api/v1/buildings/${request.buildingId}/setfavourite/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const unsetFavourite = request =>
    http
      .patch(`api/v1/buildings/${request.buildingId}/unsetfavourite/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchBuildingsFilterItems = () =>
    http
      .get("/api/v1/buildings/filteritems/")
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const createBuilding = request =>
    http
      .post("/api/v1/buildings/", request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const deleteBuilding = request =>
    http
      .delete(`api/v1/buildings/${request.buildingId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchBuildingGroups = request =>
    http
      .get(`api/v1/buildings/groups/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchPromotedBuildings = request =>
    http
      .get(`api/v1/buildings/promotedbuildings/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const promoteBuilding = buildingId =>
    http
      .patch(`api/v1/buildings/${buildingId}/promotebuilding/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const unPromoteBuilding = buildingId =>
    http
      .patch(`api/v1/buildings/${buildingId}/unpromotebuilding/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const updatePromotedBuildingList = request =>
    http
      .patch(`api/v1/buildings/updatepromotedlist/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const archiveBuilding = buildingId =>
    http
      .patch(`api/v1/buildings/${buildingId}/archivebuilding/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const unArchiveBuilding = buildingId =>
    http
      .patch(`api/v1/buildings/${buildingId}/unarchivebuilding/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  const fetchBuildingSuites = buildingId =>
    http
      .get(`/api/v1/buildings/${buildingId}/buildingsuites/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error.response.data));

  return {
    fetchBuilding,
    fetchBuildings,
    updateBuilding,
    setFavourite,
    unsetFavourite,
    fetchBuildingsFilterItems,
    createBuilding,
    deleteBuilding,
    fetchBuildingGroups,
    fetchPromotedBuildings,
    promoteBuilding,
    unPromoteBuilding,
    updatePromotedBuildingList,
    archiveBuilding,
    unArchiveBuilding,
    fetchBuildingSuites
  };
};

const api = BuildingsService();
export default api;
