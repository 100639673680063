import * as buildingContactConstants from "../constants/buildingContactConstants";
import update from "immutability-helper";

const initialState = {
  buildingContactsPage: {
    results: []
  },
  allContactsPage: {
    results: []
  }
};

const reducer = function buildingContactReducer(state = initialState, action) {
  switch (action.type) {
    case buildingContactConstants.FETCH_BUILDING_CONTACTS_SUCCESSFUL:
      return Object.assign({}, state, {
        buildingContactsPage: action.payload
      });

    case buildingContactConstants.FETCH_ALL_CONTACTS_SUCCESSFUL:
      return Object.assign({}, state, {
        allContactsPage: action.payload
      });

    case buildingContactConstants.UPDATE_CONTACT_ORDER_SUCCESS:
      return {
        ...state,
        buildingContactsPage: {
          ...state.buildingContactsPage,
          results: action.unorderedContacts
        }
      }
    default:
      return state;
  }
};

export default reducer;
