import React from "react";
import "./styles.css";
import ReactTooltip from "react-tooltip";
import { injectIntl, FormattedMessage } from "react-intl";

const InformationToolTip = ({ messageID, defaultMessage }) => (
  <span>
    <div data-tip data-for="tooltip" className="information-icon-container" />
    <ReactTooltip id="tooltip">
      <div className="tooltip-text">
        <FormattedMessage id={messageID} defaultMessage={defaultMessage} />
      </div>
    </ReactTooltip>
  </span>
);

export default injectIntl(InformationToolTip);
