const config = {
  API_SERVER_HOSTNAME: process.env.REACT_APP_API_SERVER_HOSTNAME,
  API_SERVER_PORT: process.env.REACT_APP_API_SERVER_PORT,
  HTTPS_ENABLED: process.env.REACT_APP_HTTPS_ENABLED,
  GOOGLE_RECAPTCHA_SITEKEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,
  GOOGLE_MAPS_API_VERSION: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,
  GOOGLE_MAPS_KEY_TYPE: process.env.REACT_APP_GOOGLE_MAPS_KEY_TYPE,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_CLIENT_ID: process.env.REACT_APP_GOOGLE_MAPS_CLIENT_ID,
  GOOGLE_ANALYTICS_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
  GOOGLE_ANALYTICS_KEY_DEV_TEST:
    process.env.REACT_APP_GOOGLE_ANALYTICS_KEY_DEV_TEST,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN
};

/**
 * Remove null check once react fixes dotenv blank overrides
 */
config.API_SERVER_PORT =
  config.API_SERVER_PORT === "null" ? "" : config.API_SERVER_PORT;

const protocol = config.HTTPS_ENABLED === "true" ? "https" : "http";
const apiServerBaseUrl = `${protocol}://${config.API_SERVER_HOSTNAME}${
  config.API_SERVER_PORT ? ":" + config.API_SERVER_PORT : ""
}`;

config.API_SERVER_BASE_URI = apiServerBaseUrl;

/**
 * Google Key assignment.
 * Depending on the value of GOOGLE_MAPS_KEY_TYPE (api/clientId)
 * use the relevant key type for maps api throughout the application
 * (GOOGLE_MAPS_API_KEY/GOOGLE_MAPS_CLIENT_ID)
 *
 * At some places, where a differnt key needs to be used, it has been
 * fetched directly from config
 */

if (
  config.GOOGLE_MAPS_KEY_TYPE !== "api" &&
  config.GOOGLE_MAPS_KEY_TYPE !== "clientId"
) {
  throw new Error(
    "REACT_APP_GOOGLE_KEY_TYPE value incorrect. Use either 'api' or 'clientId' as the value."
  );
}

export default config;
