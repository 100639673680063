import * as types from "./constants";
import Cookies from "js-cookie";
import update from "immutability-helper";

const tokenKey = "ArcestraAuthenticationToken";

const initialState = {
  token: Cookies.get(tokenKey) ? Cookies.get(tokenKey) : null,
  tokenVerificationInProgress: false,
  tokenVerified: false,
  tokenVerificationFailed: false,

  authenticationInProgress: false,
  authenticated: false,

  lastRefreshTime: null,
  tokenRefreshInProgress: false,
  tokenRefreshed: false,

  // Reset Password State
  resetPasswordKeyState: types.resetPasswordKeyState.SUPPLIED
};

const reducer = function login(state = initialState, action) {
  switch (action.type) {
    // User authentication
    case types.AUTHENTICATE_USER:
      return Object.assign({}, state, {
        authenticationInProgress: true,
        successful: false
      });

    case types.AUTHENTICATION_SUCCESSFUL:
      return Object.assign({}, state, {
        token: action.payload.token,
        authenticated: true,
        authenticationInProgress: false
      });

    case types.AUTHENTICATION_FAILED:
      return Object.assign({}, state, {
        token: null,
        authenticated: false,
        authenticationInProgress: false
      });

    // Token verification
    case types.VERIFY_TOKEN:
      return Object.assign({}, state, {
        tokenVerificationInProgress: true,
        tokenVerificationFailed: false
      });

    case types.TOKEN_VERIFICATION_SUCCESSFUL:
      return Object.assign({}, state, {
        tokenVerified: true,
        tokenVerificationInProgress: false,
        tokenVerificationFailed: false,
        authenticated: true
      });

    case types.TOKEN_VERIFICATION_FAILED:
      return Object.assign({}, state, {
        tokenVerified: false,
        tokenVerificationInProgress: false,
        tokenVerificationFailed: true,
        authenticated: false
      });

    // Token refresh
    case types.REFRESH_TOKEN:
      return Object.assign({}, state, {
        tokenRefreshInProgress: true
      });

    case types.TOKEN_REFRESH_SUCCESSFUL:
      return Object.assign({}, state, {
        token: action.payload,
        tokenRefreshInProgress: false,
        authenticated: true,
        tokenRefreshed: true,
        lastRefreshTime: new Date().valueOf()
      });

    case types.TOKEN_REFRESH_FAILED:
      return Object.assign({}, state, {
        tokenRefreshInProgress: false,
        authenticated: false,
        tokenRefreshed: false
      });

    case types.VALIDATE_RESET_PASSWORD_KEY_SUCCESSFUL:
      return update(state, {
        resetPasswordKeyState: { $set: types.resetPasswordKeyState.VALID }
      });

    case types.VALIDATE_RESET_PASSWORD_KEY_FAILED:
      return update(state, {
        resetPasswordKeyState: { $set: types.resetPasswordKeyState.INVALID }
      });

    case types.RESET_PASSWORD_KEY_NOT_SUPPLIED:
      return update(state, {
        resetPasswordKeyState: {
          $set: types.resetPasswordKeyState.NOT_SUPPLIED
        }
      });

    case types.RESET_PASSWORD_SUCCESSFUL:
      return update(state, {
        errors: { $set: null }
      });

    case types.RESET_PASSWORD_FAILED:
      return update(state, {
        errors: { $set: action.error }
      });

    case types.RESET_PASSWORD_PAGE_UNMOUNT:
      return update(state, {
        resetPasswordKeyState: { $set: types.resetPasswordKeyState.SUPPLIED }
      });

    case types.FORCE_PASSWORD_CHANGE_SUCCESSFUL:
      return update(state, {
        errors: { $set: null }
      });

    case types.FORCE_PASSWORD_CHANGE_FAILED:
      return update(state, {
        errors: { $set: action.error }
      });

    default:
      return state;
  }
};

export default reducer;
