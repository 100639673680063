export const AUTHENTICATE_USER = "Auth/AUTHENTICATE_USER";
export const AUTHENTICATION_SUCCESSFUL = "Auth/AUTHENTICATION_SUCCESSFUL";
export const AUTHENTICATION_FAILED = "Auth/AUTHENTICATION_FAILED";

export const VERIFY_TOKEN = "Auth/VERIFY_TOKEN";
export const TOKEN_VERIFICATION_SUCCESSFUL =
  "Auth/TOKEN_VERIFICATION_SUCCESSFUL";
export const TOKEN_VERIFICATION_FAILED = "Auth/TOKEN_VERIFICATION_FAILED";

export const REFRESH_TOKEN = "Auth/REFRESH_TOKEN";
export const TOKEN_REFRESH_SUCCESSFUL = "Auth/TOKEN_REFRESH_SUCCESSFUL";
export const TOKEN_REFRESH_FAILED = "Auth/TOKEN_REFRESH_FAILED";

export const LOGOUT_USER = "Auth/LOGOUT_USER";
export const LOGOUT_SUCCESSFUL = "Auth/LOGOUT_SUCCESSFUL";
export const LOGOUT_FAILED = "Auth/LOGOUT_FAILED";

export const CLEAR_APP_STATE = "Auth/CLEAR_APP_STATE";

export const FORGOT_PASSWORD = "Auth/FORGOT_PASSWORD";

export const VALIDATE_RESET_PASSWORD_KEY = "Auth/VALIDATE_RESET_PASSWORD_KEY";
export const VALIDATE_RESET_PASSWORD_KEY_SUCCESSFUL =
  "Auth/VALIDATE_RESET_PASSWORD_KEY_SUCCESSFUL";
export const VALIDATE_RESET_PASSWORD_KEY_FAILED =
  "Auth/VALIDATE_RESET_PASSWORD_KEY_FAILED";
export const RESET_PASSWORD_KEY_NOT_SUPPLIED =
  "Auth/RESET_PASSWORD_KEY_NOT_SUPPLIED";

export const RESET_PASSWORD = "Auth/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESSFUL = "Auth/RESET_PASSWORD_SUCCESSFUL";
export const RESET_PASSWORD_FAILED = "Auth/RESET_PASSWORD_FAILED";

export const RESET_PASSWORD_PAGE_UNMOUNT = "Auth/RESET_PASSWORD_PAGE_UNMOUNT";

export const resetPasswordKeyState = {
  NOT_SUPPLIED: "NOT_SUPPLIED",
  SUPPLIED: "SUPPLIED",
  VALID: "VALID",
  INVALID: "INVALID"
};

export const FORCE_PASSWORD_CHANGE = "Auth/FORCE_PASSWORD_CHANGE";
export const FORCE_PASSWORD_CHANGE_SUCCESSFUL =
  "Auth/FORCE_PASSWORD_CHANGE_SUCCESSFUL";
export const FORCE_PASSWORD_CHANGE_FAILED = "Auth/FORCE_PASSWORD_CHANGE_FAILED";
