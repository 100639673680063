import React from "react";
import config from "./config";

export const convertLOVtoOptions = types => {
  return types.map((type, i) => ({ value: type.id, label: type.value }));
};

export const getPlaceholderImage = (dimensions, text) => {
  dimensions = dimensions || "100x70";
  return `${
    config.HTTPS_ENABLED === "true" ? "http" : "https"
  }://via.placeholder.com/${dimensions}/ddd/bbb?text=${text}`;
};

export const getNoImagePlaceholder = (
  text,
  returnOnlyFirstChar,
  defaultText = "A"
) => {
  const str = !!text
    ? returnOnlyFirstChar
      ? text.charAt(0).toUpperCase()
      : text
    : defaultText;
  return <div className="no-image-placeholder-container">{str}</div>;
};

export const getBuildingImageURI = (
  building,
  placeholderDimensions,
  placeholderText
) => {
  placeholderDimensions = placeholderDimensions || "100x70";
  placeholderText =
    placeholderText || (building.name ? building.name.substring(0, 1) : "A");

  return building.image
    ? building.image
    : getPlaceholderImage(placeholderDimensions, placeholderText);
};

export const isBuildingImageAvailable = building => {
  return !!building.image;
};

export const getGoogleAPIKeyValuePair = () => {
  return config.GOOGLE_MAPS_KEY_TYPE === "api"
    ? { keyName: "key", value: config.GOOGLE_MAPS_API_KEY }
    : { keyName: "client", value: config.GOOGLE_MAPS_CLIENT_ID };
};

export const intlNumber = (intl, value) => {
  return value === null || value === ""
    ? value
    : intl.formatNumber(value, { minimumFractionDigits: 2 });
};

export const decimalFeetStringToInches = decimalFeetString => {
  /*
    Changes a string representing feet (decimal) to inches
    e.g. "5" -> 6 in.
  */
  const decimalFeetNumber = parseInt(decimalFeetString);
  return Math.round(
    (decimalFeetNumber / Math.pow(10, decimalFeetString.length)) * 12
  );
};

export const inchesToFeet = inches => {
  /*
    Changes a number representing inches to feet
    e.g. 6 in. -> 5
  */
  if (inches < 0) throw "Inches must be positive";
  return inches / 12;
};
