import { takeEvery, call, put } from "redux-saga/effects";
import { startProgress, stopProgress } from "./sagaUtils";
import uuid from "uuid/v4";

import * as buildingContactConstants from "../constants/buildingContactConstants";
import BuildingContactService from "../services/buildingContactService";

function* fetchBuildingContacts(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const result = yield call(
      BuildingContactService.fetchBuildingContacts,
      action.payload
    );
    yield put({
      type: buildingContactConstants.FETCH_BUILDING_CONTACTS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingContactConstants.FETCH_BUILDING_CONTACTS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* fetchAllContacts(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  try {
    const result = yield call(
      BuildingContactService.fetchAllContacts,
      action.payload
    );
    yield put({
      type: buildingContactConstants.FETCH_ALL_CONTACTS_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({
      type: buildingContactConstants.FETCH_ALL_CONTACTS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* updateBuildingContact(action) {

  const progressId = uuid();
  yield put(startProgress(progressId));

  const { contactId, contact, onSuccess } = action.payload;

  try {
    const result = yield call(
      BuildingContactService.updateBuildingContact,
      contactId,
      contact
    );
    yield put({
      type: buildingContactConstants.UPDATE_BUILDING_CONTACT_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingContactConstants.UPDATE_BUILDING_CONTACT_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* deleteBuildingContact(action) {
  const progressId = uuid();
  yield put(startProgress(progressId));

  const { contactId, onSuccess } = action.payload;

  try {
    const result = yield call(
      BuildingContactService.deleteBuildingContact,
      contactId
    );
    yield put({
      type: buildingContactConstants.DELETE_BUILDING_CONTACT_SUCCESSFUL,
      payload: result
    });

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingContactConstants.DELETE_BUILDING_CONTACT_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

function* addBuildingContacts(action) {

  const progressId = uuid();
  yield put(startProgress(progressId));

  const { contacts, buildingId, contactType, onSuccess } = action.payload;

  try {
    yield call(
      BuildingContactService.addBuildingContacts,
      buildingId,
      contacts,
      contactType
    );

    !!onSuccess && onSuccess();
  } catch (error) {
    yield put({
      type: buildingContactConstants.ADD_BUILDING_CONTACTS_FAILED,
      error
    });
  } finally {
    yield put(stopProgress(progressId));
  }
}

export function* updateBuildingContactsOrder(action) {
  const progressId = uuid()
  yield (put(startProgress(progressId)));

  const { sourceIndex, targetIndex, unorderedContacts, buildingId } = action.payload
  let length = unorderedContacts.length

  let sourceElement = unorderedContacts.splice(sourceIndex, 1)[0]
  unorderedContacts.splice(targetIndex, 0, sourceElement)

  for (var k = 0; k < length; k++) {
    unorderedContacts[k].order = k
  }

  let payload = { contacts: unorderedContacts, buildingId: buildingId }
  try {
    yield call(
      BuildingContactService.updateBuildingContactsOrderService,
      payload
    );
    yield put({
      type: buildingContactConstants.UPDATE_CONTACT_ORDER_SUCCESS,
      unorderedContacts
    })
  } catch (error) {
    yield put({
      type: buildingContactConstants.UPDATE_CONTACT_ORDER_FAILED,
      error
    })
  } finally {
    yield put(stopProgress(progressId))
  }
}

export function* fetchBuildingContactsWatcher() {
  yield takeEvery(
    buildingContactConstants.FETCH_BUILDING_CONTACTS,
    fetchBuildingContacts
  );
}

export function* fetchAllContactsWatcher() {

  yield takeEvery(
    buildingContactConstants.FETCH_ALL_CONTACTS,
    fetchAllContacts
  );
}

export function* updateBuildingContactWatcher() {

  yield takeEvery(
    buildingContactConstants.UPDATE_BUILDING_CONTACT,
    updateBuildingContact
  );
}

export function* deleteBuildingContactWatcher() {

  yield takeEvery(
    buildingContactConstants.DELETE_BUILDING_CONTACT,
    deleteBuildingContact
  );
}

export function* addBuildingContactsWatcher() {

  yield takeEvery(
    buildingContactConstants.ADD_BUILDING_CONTACTS,
    addBuildingContacts
  )
}
export function* updateBuildingContactsOrderWatcher() {
  yield takeEvery(
    buildingContactConstants.UPDATE_CONTACT_ORDER,
    updateBuildingContactsOrder
  );
}
