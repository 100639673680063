import http, { noAuthHttp } from "../auth/http";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import ReactGA from "react-ga";

const tokenKey = "ArcestraAuthenticationToken";
const forcePasswordChangeResponse = "FORCE_PASSWORD_CHANGE";

const AuthenticationService = () => {
  const state = {
    callback: null,
    tokenCallback: null,
    tokenVerified: false,
    verificationInProgress: false,
    lastRefreshTime: null
  };

  const getToken = () => Cookies.get(tokenKey);

  const setToken = token => {
    console.log("[authService.js] setToken");
    Cookies.set(tokenKey, token, { expires: 14 });
    //http.defaults.headers.common.Authorization = `JWT ${token}`;
    state.tokenVerified = true;
  };

  const removeToken = () => {
    console.log("[authService.js] removeToken");
    Cookies.remove(tokenKey);
    //http.defaults.headers.common.Authorization = '';
    state.tokenVerified = false;
  };

  const signalStateChange = authenticated => {
    console.log("[authService.js] signalStateChangedToken");
    if (state.callback && typeof state.callback === "function") {
      state.callback(authenticated);
    }
  };

  const signalTokenVerified = (verified, expired) => {
    console.log("[authService.js] signalTokenVerified");
    expired = expired || false;
    if (state.tokenCallback && typeof state.tokenCallback === "function") {
      state.tokenCallback(verified, expired);
    }
  };

  const hasToken = () => {
    //console.log("[authService.js] hasToken");
    const token = getToken();
    return token !== null && token !== undefined;
  };

  const tokenVerified = () => state.tokenVerified;

  /** Only call this function if the hasToken() check returned true.
   */
  const verifyToken = () => {
    if (state.verificationInProgress) {
      return Promise.reject("in_progress");
    }
    state.verificationInProgress = true;

    return new Promise((resolve, reject) => {
      http
        .post("api/v1/token/verify", {
          token: getToken()
        })
        .then(response => {
          state.verificationInProgress = false;
          setToken(response.data.token);
          signalTokenVerified(true);
          resolve();
        })
        .catch(error => {
          if (error) {
            if (error.response.data === forcePasswordChangeResponse) {
              state.verificationInProgress = false;
              signalTokenVerified(false);
              reject(forcePasswordChangeResponse);
            } else {
              state.verificationInProgress = false;
              removeToken();
              signalTokenVerified(false);
              reject("invalid_token");
            }
          }
        });
    });
  };

  const user = () => {
    if (!hasToken()) {
      return {};
    }
    const decoded = jwtDecode(getToken());
    ReactGA.set({ userId: decoded.user_id });
    return {
      username: decoded.username,
      id: decoded.user_id,
      email: decoded.email
    };
  };

  const login = (username, password) => {
    removeToken();

    return new Promise((resolve, reject) => {
      http
        .post("api/v1/token/auth", {
          username,
          password
        })
        .then(response => {
          setToken(response.data.token);
          const authenticated = true;
          signalStateChange(authenticated);
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  };

  const onLogout = () => {
    removeToken();
    ReactGA.set({ userId: null });
    window.location.reload();
    //const authenticated = false;
    //signalStateChange(authenticated);
  };

  const logout = () => {
    // http.post('api/v1/logout/', {
    //   token: getToken(),
    // });
    onLogout();
  };

  const refreshToken = () => {
    if (!hasToken()) {
      return;
    }

    if (state.lastRefreshTime !== null) {
      const timeSinceLastRefresh = new Date().valueOf() - state.lastRefreshTime;
      if (timeSinceLastRefresh < 45000) {
        return;
      }
    }

    return new Promise((resolve, reject) => {
      http
        .post("api/v1/token/refresh", {
          token: getToken()
        })
        .then(response => {
          setToken(response.data.token);
          signalTokenVerified(true);
          state.lastRefreshTime = new Date().valueOf();
          console.log("Token refreshed");
          resolve(response.data.token);
        })
        .catch(error => {
          if (error) {
            onLogout();
            const tokenExpired = true;
            signalTokenVerified(false, tokenExpired);
          }
          reject();
        });
    });
  };

  const setCallback = callback => {
    state.callback = callback;
  };

  const setTokenCallback = callback => {
    state.tokenCallback = callback;
  };

  const forgotPassword = request =>
    noAuthHttp
      .post(`/api/v1/users/forgotpassword/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => {
        // We treat 404 errors as success to avoid exposing
        // the potential security issue that this API identifies
        // whether or not a username exists in the system.
        if (error.response.status !== 404) {
          return Promise.reject(error.response.data);
        }
      });

  const validateResetPasswordKey = request =>
    noAuthHttp
      .post(`/api/v1/users/validatekey/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const resetPassword = request =>
    noAuthHttp
      .post(`/api/v1/users/resetpassword/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  const forcePasswordChange = request =>
    http
      .post(`/api/v1/users/force-password-change/`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));

  return {
    removeToken,
    getToken,
    setToken,
    hasToken,
    tokenVerified,
    verifyToken,
    refreshToken,
    login,
    logout,
    setCallback,
    setTokenCallback,
    user,
    forgotPassword,
    validateResetPasswordKey,
    resetPassword,
    forcePasswordChange,
    forcePasswordChangeResponse
  };
};

const api = AuthenticationService();
export default api;
