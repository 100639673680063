export const FETCH_DASHBOARD_META = "dashboard/FETCH_DASHBOARD_META";
export const FETCH_DASHBOARD_META_SUCCESSFUL =
  "dashboard/FETCH_DASHBOARD_META_SUCCESSFUL";
export const FETCH_DASHBOARD_META_FAILED =
  "dashboard/FETCH_DASHBOARD_META_FAILED";

export const FETCH_DASHBOARD_FILTERITEMS =
  "dashboard/FETCH_DASHBOARD_FILTERITEMS";
export const FETCH_DASHBOARD_FILTERITEMS_SUCCESSFUL =
  "dashboard/FETCH_DASHBOARD_FILTERITEMS_SUCCESSFUL";
export const FETCH_DASHBOARD_FILTERITEMS_FAILED =
  "dashboard/FETCH_DASHBOARD_FILTERITEMS_FAILED";

export const FETCH_DISTRIBUTION = "dashboard/FETCH_DISTRIBUTION";
export const FETCH_DISTRIBUTION_SUCCESSFUL =
  "dashboard/FETCH_DISTRIBUTION_SUCCESSFUL";
export const FETCH_DISTRIBUTION_FAILED = "dashboard/FETCH_DISTRIBUTION_FAILED";

export const FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE =
  "dashboard/FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE";
export const FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_SUCCESSFUL =
  "dashboard/FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_SUCCESSFUL";
export const FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_FAILED =
  "dashboard/FETCH_DASHBOARD_LEASE_EXPIRY_PROFILE_FAILED";

export const TOGGLE_FILTER_CHECKBOX = "dashboard/TOGGLE_FILTER_CHECKBOX";
export const SELECT_FILTER_DROPDOWN_ITEM =
  "dashboard/SELECT_FILTER_DROPDOWN_ITEM";
export const SAVE_FILTERS = "dashboard/SAVE_FILTERS";
export const RESET_FILTERS = "dashboard/RESET_FILTERS";
