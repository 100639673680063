import http from "../auth/http";

const AccountService = () => {
  const changePassword = (
    userId,
    currentPassword,
    password,
    passwordConfirmation
  ) => {
    const data = new FormData();
    if (currentPassword) {
      data.append("current", currentPassword);
    }
    data.append("password1", password);
    data.append("password2", passwordConfirmation);

    return http.patch(`api/v1/users/${userId}/set_password/`, data);
  };

  return {
    changePassword
  };
};

const api = AccountService();
export default api;
