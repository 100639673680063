import { takeEvery, call, put } from "redux-saga/effects";

import * as lovConstants from "../constants/lovConstants";
import LovService from "../services/lovService";

const SUITE_TYPE = {
  I: {
    id: "codes.suite-type.I",
    defaultMessage: "Industrial"
  },
  O: {
    id: "codes.suite-type.O",
    defaultMessage: "Office"
  },
  E: {
    id: "codes.suite-type.E",
    defaultMessage: "Residential"
  },
  R: {
    id: "codes.suite-type.R",
    defaultMessage: "Retail"
  },
  S: {
    id: "codes.suite-type.S",
    defaultMessage: "Retirement"
  },
  Z: {
    id: "codes.suite-type.Z",
    defaultMessage: "Other Leasable"
  }
};

const SUITE_AVAILABILITY_TYPE = {
  P: {
    id: "codes.suite-availability-type.P",
    defaultMessage: "Public"
  },
  PR: {
    id: "codes.suite-availability-type.PR",
    defaultMessage: "Private"
  },
  L: {
    id: "codes.suite-availability-type.L",
    defaultMessage: "Leased"
  },
  C: {
    id: "codes.suite-availability-type.C",
    defaultMessage: "Committed"
  }
};

const BUILDING_TYPE = {
  O: {
    id: "codes.building-type.O",
    defaultMessage: "Office"
  },
  E: {
    id: "codes.building-type.E",
    defaultMessage: "Residential"
  },
  R: {
    id: "codes.building-type.R",
    defaultMessage: "Retail"
  },
  I: {
    id: "codes.building-type.I",
    defaultMessage: "Industrial"
  },
  L: {
    id: "codes.building-type.L",
    defaultMessage: "Land"
  }
};

const PUB_STATUS = {
  P: {
    id: "codes.buildings.pub-status.P",
    defaultMessage: "Published"
  },
  NP: {
    id: "codes.buildings.pub-status.NP",
    defaultMessage: "Not Published"
  }
};

const FAV_STATUS = {
  F: {
    id: "codes.buildings.fav-status.F",
    defaultMessage: "Favourites"
  },
  NF: {
    id: "codes.buildings.fav-status.NF",
    defaultMessage: "Not Favourites"
  }
};

const ARCHIVED_STATUS = {
  A: {
    id: "codes.buildings.archived-status.A",
    defaultMessage: "Archived"
  },
  NA: {
    id: "codes.buildings.archived-status.NA",
    defaultMessage: "Not Archived"
  }
};

const LISTING_STATUS = {
  L: {
    id: "codes.listing-status.L",
    defaultMessage: "Listed"
  },
  C: {
    id: "codes.listing-status.C",
    defaultMessage: "Call For Availability"
  },
  F: {
    id: "codes.listing-status.F",
    defaultMessage: "Fully Leased"
  }
};

const RESIDENTIAL_SUITE_TYPE = {
  B: {
    id: "codes.residential-suite-type.B",
    defaultMessage: "Bachelor"
  },
  "1": {
    id: "codes.residential-suite-type.1",
    defaultMessage: "One Bedroom"
  },
  "2": {
    id: "codes.residential-suite-type.2",
    defaultMessage: "Two Bedroom"
  },
  "3": {
    id: "codes.residential-suite-type.3",
    defaultMessage: "Three Bedroom"
  },
  "4": {
    id: "codes.residential-suite-type.4",
    defaultMessage: "Four Bedroom"
  },
  "5": {
    id: "codes.residential-suite-type.5",
    defaultMessage: "Five Bedroom+"
  },
  T: {
    id: "codes.residential-suite-type.T",
    defaultMessage: "Townhouse"
  }
};

const SUITE_CONDITION_TYPE = {
  F: {
    id: "codes.suite-condition-type.F",
    defaultMessage: "Fully Built Out"
  },
  "7": {
    id: "codes.suite-condition-type.7",
    defaultMessage: "75 % Complete"
  },
  "5": {
    id: "codes.suite-condition-type.5",
    defaultMessage: "50 % Complete"
  },
  "1": {
    id: "codes.suite-condition-type.1",
    defaultMessage: "10 % Complete"
  },
  B: {
    id: "codes.suite-condition-type.B",
    defaultMessage: "Base Building"
  }
};

const RESIDENTIAL_BUILDING_TYPE = {
  C: {
    id: "codes.residential-building-type.C",
    defaultMessage: "Condominium"
  },
  L: {
    id: "codes.residential-building-type.L",
    defaultMessage: "Lowrise"
  },
  M: {
    id: "codes.residential-building-type.M",
    defaultMessage: "Midrise"
  },
  H: {
    id: "codes.residential-building-type.H",
    defaultMessage: "Highrise"
  },
  T: {
    id: "codes.residential-building-type.T",
    defaultMessage: "Townhouse"
  },
  B: {
    id: "codes.residential-building-type.B",
    defaultMessage: "Mobile"
  }
};

const USER_TYPE = {
  AA: {
    id: "codes.user-types.AA",
    defaultMessage: "Arcestra Administrator"
  },
  CA: {
    id: "codes.user-types.CA",
    defaultMessage: "Company Administrator"
  },
  LA: {
    id: "codes.user-types.LA",
    defaultMessage: "Leasing Administrator"
  },
  LB: {
    id: "codes.user-types.LB",
    defaultMessage: "Leasing Broker"
  }
};

const EXTERIOR_WALL_TYPE = {
  B: { id: "codes.exterior-wall-types.B", defaultMessage: "Brick" },
  S: { id: "codes.exterior-wall-types.S", defaultMessage: "Stone" },
  M: { id: "codes.exterior-wall-types.M", defaultMessage: "Metal" },
  G: { id: "codes.exterior-wall-types.G", defaultMessage: "Glass" },
  T: { id: "codes.exterior-wall-types.T", defaultMessage: "Stucco" },
  P: {
    id: "codes.exterior-wall-types.P",
    defaultMessage: "Pre-Cast Slab Concrete"
  },
  O: { id: "codes.exterior-wall-types.O", defaultMessage: "Other" }
};

const ROOFING_TYPE = {
  M: { id: "codes.roofing-types.M", defaultMessage: "Metal" },
  B: { id: "codes.roofing-types.B", defaultMessage: "Membrane" },
  T: { id: "codes.roofing-types.T", defaultMessage: "Tile" },
  C: { id: "codes.roofing-types.C", defaultMessage: "Concrete" },
  O: { id: "codes.roofing-types.O", defaultMessage: "Other" }
};

const BOMA_TYPE = {
  P: { id: "codes.boma-types.P", defaultMessage: "Platinum" },
  G: { id: "codes.boma-types.G", defaultMessage: "Gold" },
  S: { id: "codes.boma-types.S", defaultMessage: "Silver" },
  B: { id: "codes.boma-types.B", defaultMessage: "Bronze" },
  C: { id: "codes.boma-types.C", defaultMessage: "Certified" }
};

const LEED_TYPE = {
  P: { id: "codes.leed-types.P", defaultMessage: "Platinum" },
  G: { id: "codes.leed-types.G", defaultMessage: "Gold" },
  S: { id: "codes.leed-types.S", defaultMessage: "Silver" },
  C: { id: "codes.leed-types.C", defaultMessage: "Certified" }
};

const ZONED_TYPE = {
  C: { id: "codes.zoned-types.C", defaultMessage: "Commercial" },
  I: { id: "codes.zoned-types.I", defaultMessage: "Industrial" },
  R: { id: "codes.zoned-types.R", defaultMessage: "Retail" },
  E: { id: "codes.zoned-types.E", defaultMessage: "Residential" },
  N: { id: "codes.zoned-types.N", defaultMessage: "Institutional" },
  O: { id: "codes.zoned-types.O", defaultMessage: "Other Leasable" }
};

const SALE_OR_LEASE_TYPE = {
  S: { id: "codes.sale-or-lease-types.S", defaultMessage: "Sale" },
  L: { id: "codes.sale-or-lease-types.L", defaultMessage: "Lease" }
};

const LAND_TYPE = {
  R: { id: "codes.land-types.R", defaultMessage: "Rural" },
  U: { id: "codes.land-types.U", defaultMessage: "Urban" },
  C: { id: "codes.land-types.C", defaultMessage: "Recreational" }
};

const PROPERTY_SUB_TYPE = {
  C: { id: "codes.property-sub-types.C", defaultMessage: "Commercial" },
  R: { id: "codes.property-sub-types.R", defaultMessage: "Recreational" },
  I: { id: "codes.property-sub-types.I", defaultMessage: "Industrial" },
  E: { id: "codes.property-sub-types.E", defaultMessage: "Retail" }
};

const PROPOSED_USE_TYPE = {
  I: { id: "codes.proposed-use-types.I", defaultMessage: "Industrial" },
  O: { id: "codes.proposed-use-types.O", defaultMessage: "Office" },
  R: { id: "codes.proposed-use-types.R", defaultMessage: "Retail" },
  E: { id: "codes.proposed-use-types.E", defaultMessage: "Residential" },
  C: { id: "codes.proposed-use-types.C", defaultMessage: "Recreational" }
};

const PERMITTED_USE_TYPES = {
  R: { id: "codes.permitted-use-types.R", defaultMessage: "Retail" },
  I: { id: "codes.permitted-use-types.I", defaultMessage: "Industrial" },
  E: { id: "codes.permitted-use-types.E", defaultMessage: "Residential" },
  C: { id: "codes.permitted-use-types.C", defaultMessage: "Commercial" },
  U: { id: "codes.permitted-use-types.U", defaultMessage: "Rural" }
};

const ZONING_TYPES = {
  I: { id: "codes.zoning-types.I", defaultMessage: "Industrial" },
  O: { id: "codes.zoning-types.O", defaultMessage: "Office" },
  C: { id: "codes.zoning-types.C", defaultMessage: "Commercial" },
  R: { id: "codes.zoning-types.R", defaultMessage: "Residential" },
  A: { id: "codes.zoning-types.A", defaultMessage: "Agricultural" },
  M: { id: "codes.zoning-types.M", defaultMessage: "Mixed" }
};

const FRONTAGE_TYPES = {
  U: { id: "codes.frontage-types.U", defaultMessage: "County" },
  C: { id: "codes.frontage-types.C", defaultMessage: "City" },
  P: { id: "codes.frontage-types.P", defaultMessage: "Private" }
};

const SURFACING_TYPES = {
  A: { id: "codes.surfacing-types.A", defaultMessage: "Asphalt" },
  G: { id: "codes.surfacing-types.G", defaultMessage: "Gravel" },
  C: { id: "codes.surfacing-types.C", defaultMessage: "Concrete" }
};

const WATERFRONT_TYPES = {
  L: { id: "codes.waterfront-types.L", defaultMessage: "Lake" },
  O: { id: "codes.waterfront-types.O", defaultMessage: "Ocean" },
  R: { id: "codes.waterfront-types.R", defaultMessage: "River" },
  P: { id: "codes.waterfront-types.P", defaultMessage: "Pond" }
};

const CLASS_TYPES = {
  "3": { id: "codes.class-types.3", defaultMessage: "AAA" },
  A: { id: "codes.class-types.A", defaultMessage: "A" },
  B: { id: "codes.class-types.B", defaultMessage: "B" },
  C: { id: "codes.class-types.C", defaultMessage: "C" }
};

const INTERIOR_WALL_TYPE = {
  D: { id: "codes.interior-wall-types.D", defaultMessage: "Dry Wall" },
  W: { id: "codes.interior-wall-types.W", defaultMessage: "Wood" },
  S: { id: "codes.interior-wall-types.S", defaultMessage: "Stone" },
  B: { id: "codes.interior-wall-types.B", defaultMessage: "Brick" },
  O: { id: "codes.interior-wall-types.O", defaultMessage: "Other" }
};

const LEASE_TERMS = {
  F: { id: "codes.lease-terms.F", defaultMessage: "Flexible" },
  L: { id: "codes.lease-terms.L", defaultMessage: "Less Than One Year" },
  M: { id: "codes.lease-terms.M", defaultMessage: "Monthly" },
  Y: { id: "codes.lease-terms.Y", defaultMessage: "Yearly" }
};

const NOTICE_PERIOD = {
  N: { id: "codes.notice-period.N", defaultMessage: "None" },
  "3": { id: "codes.notice-period.3", defaultMessage: "30 Days" },
  "6": { id: "codes.notice-period.6", defaultMessage: "60 Days" },
  "9": { id: "codes.notice-period.9", defaultMessage: "90 Days" },
  "1": { id: "codes.notice-period.1", defaultMessage: "120 Days" },
  "8": { id: "codes.notice-period.8", defaultMessage: "180 Days" }
};

const TENANT_RIGHTS = {
  right_of_first_refusal: {
    id: "codes.tenant-rights.right_of_first_refusal",
    defaultMessage: "Right of First Refusal"
  },
  right_of_first_offer: {
    id: "codes.tenant-rights.right_of_first_offer",
    defaultMessage: "Right of First Offer"
  },
  right_of_relocation: {
    id: "codes.tenant-rights.right_of_relocation",
    defaultMessage: "Right of Relocation"
  },
  right_of_signage: {
    id: "codes.tenant-rights.right_of_signage",
    defaultMessage: "Right of Signage"
  },
  right_of_early_termination: {
    id: "codes.tenant-rights.right_of_early_termination",
    defaultMessage: "Right of Early Termination"
  },
  right_of_audit: {
    id: "codes.tenant-rights.right_of_audit",
    defaultMessage: "Right of Audit"
  },
  right_of_exclusivity: {
    id: "codes.tenant-rights.right_of_exclusivity",
    defaultMessage: "Right of Exclusivity"
  },
  right_of_contraction: {
    id: "codes.tenant-rights.right_of_contraction",
    defaultMessage: "Right of Contraction"
  },
  right_of_expansion: {
    id: "codes.tenant-rights.right_of_expansion",
    defaultMessage: "Right of Expansion"
  },
  right_of_renewal: {
    id: "codes.tenant-rights.right_of_renewal",
    defaultMessage: "Right of Renewal"
  },
  right_of_gross_up: {
    id: "codes.tenant-rights.right_of_gross_up",
    defaultMessage: "Right of Gross Up"
  }
};

const MALL_TYPES = {
  N: { id: "codes.mall-types.N", defaultMessage: "Neighborhood Centre" },
  R: { id: "codes.mall-types.R", defaultMessage: "Regional Centre" },
  S: { id: "codes.mall-types.S", defaultMessage: "Super Regional Centre" },
  P: { id: "codes.mall-types.P", defaultMessage: "Power Centre" },
  O: { id: "codes.mall-types.O", defaultMessage: "Other" }
};

const MEASUREMENT_UNITS = {
  I: { id: "codes.measurement-units.I", defaultMessage: "Imperial" },
  M: { id: "codes.measurement-units.M", defaultMessage: "Metric" }
};

const MEDIA_TAGS = {
  T: { id: "codes.media-tags.T", defaultMessage: "Thumbnail" },
  H: { id: "codes.media-tags.H", defaultMessage: "Hero" },
  P: { id: "codes.media-tags.P", defaultMessage: "Plan" },
  B: { id: "codes.media-tags.B", defaultMessage: "Brochure" }
};

const SUITE_VISIBILITY_TYPES = {
  PB: { id: "codes.suite-visibility-types.PB", defaultMessage: "Published" },
  PR: { id: "codes.suite-visibility-types.PR", defaultMessage: "Private" }
};

/**
 * Use lovTypesMap when you don't want the lov type to be handled in any
 * specific way. Otherwise use a separate saga for it. e.g.: TENANT_RIGHTS
 */
const lovTypesMap = [
  {
    id: "suiteTypes",
    messageMap: SUITE_TYPE,
    service: LovService.fetchSuiteTypes
  },
  {
    id: "suiteAvailabilityTypes",
    messageMap: SUITE_AVAILABILITY_TYPE,
    service: LovService.fetchSuiteAvailabilityTypes
  },
  {
    id: "buildingTypes",
    messageMap: BUILDING_TYPE,
    service: LovService.fetchBuildingTypes
  },
  {
    id: "pubStatus",
    messageMap: PUB_STATUS,
    service: LovService.fetchPubStatuses
  },
  {
    id: "favStatus",
    messageMap: FAV_STATUS,
    service: LovService.fetchFavStatuses
  },
  {
    id: "archivedStatus",
    messageMap: ARCHIVED_STATUS,
    service: LovService.fetchArchivedStatuses
  },
  {
    id: "listingStatus",
    messageMap: LISTING_STATUS,
    service: LovService.fetchListingStatuses
  },
  {
    id: "residentialSuiteTypes",
    messageMap: RESIDENTIAL_SUITE_TYPE,
    service: LovService.fetchResidentialSuiteTypes
  },
  {
    id: "suiteConditionTypes",
    messageMap: SUITE_CONDITION_TYPE,
    service: LovService.fetchSuiteConditionTypes
  },
  {
    id: "residentialBuildingTypes",
    messageMap: RESIDENTIAL_BUILDING_TYPE,
    service: LovService.fetchResidentialBuildingTypes
  },
  {
    id: "userTypes",
    messageMap: USER_TYPE,
    service: LovService.fetchUserTypes
  },
  {
    id: "exteriorWallTypes",
    messageMap: EXTERIOR_WALL_TYPE,
    service: LovService.fetchExteriorWallTypes
  },
  {
    id: "roofingTypes",
    messageMap: ROOFING_TYPE,
    service: LovService.fetchRoofingTypes
  },
  {
    id: "BOMATypes",
    messageMap: BOMA_TYPE,
    service: LovService.fetchBOMATypes
  },
  {
    id: "LEEDTypes",
    messageMap: LEED_TYPE,
    service: LovService.fetchLEEDTypes
  },
  {
    id: "zonedTypes",
    messageMap: ZONED_TYPE,
    service: LovService.fetchZonedTypes
  },
  {
    id: "saleOrLease",
    messageMap: SALE_OR_LEASE_TYPE,
    service: LovService.fetchSaleOrLease
  },
  {
    id: "landTypes",
    messageMap: LAND_TYPE,
    service: LovService.fetchLandTypes
  },
  {
    id: "propertySubTypes",
    messageMap: PROPERTY_SUB_TYPE,
    service: LovService.fetchPropertySubTypes
  },
  {
    id: "proposedUseTypes",
    messageMap: PROPOSED_USE_TYPE,
    service: LovService.fetchProposedUseTypes
  },
  {
    id: "permittedUseTypes",
    messageMap: PERMITTED_USE_TYPES,
    service: LovService.fetchPermittedUseTypes
  },
  {
    id: "zoningTypes",
    messageMap: ZONING_TYPES,
    service: LovService.fetchZoningTypes
  },
  {
    id: "frontageTypes",
    messageMap: FRONTAGE_TYPES,
    service: LovService.fetchFrontageTypes
  },
  {
    id: "surfacingTypes",
    messageMap: SURFACING_TYPES,
    service: LovService.fetchSurfacingTypes
  },
  {
    id: "waterfrontTypes",
    messageMap: WATERFRONT_TYPES,
    service: LovService.fetchWaterfrontTypes
  },
  {
    id: "classTypes",
    messageMap: CLASS_TYPES,
    service: LovService.fetchClassTypes
  },
  {
    id: "interiorWallTypes",
    messageMap: INTERIOR_WALL_TYPE,
    service: LovService.fetchInteriorWallTypes
  },
  {
    id: "leaseTerms",
    messageMap: LEASE_TERMS,
    service: LovService.fetchLeaseTerms
  },
  {
    id: "noticePeriod",
    messageMap: NOTICE_PERIOD,
    service: LovService.noticePeriod
  },
  {
    id: "mallTypes",
    messageMap: MALL_TYPES,
    service: LovService.fetchMallTypes
  },
  {
    id: "measurementUnits",
    messageMap: MEASUREMENT_UNITS,
    service: LovService.fetchMeasurementUnits
  },
  {
    id: "mediaTags",
    messageMap: MEDIA_TAGS,
    service: LovService.fetchMediaTags
  }
];

function* fetchAllLOVTypes(action) {
  const { formatMessage } = action.payload;
  try {
    const lovs = {};

    lovTypesMap.forEach(lov => {
      let types = lov.service();
      types = types.map(type => {
        const message = lov.messageMap[type.id];
        return {
          id: type.id,
          value: formatMessage({
            id: message.id,
            defaultMessage: message.defaultMessage
          })
        };
      });

      lovs[lov.id] = types;
    });

    yield put({
      type: lovConstants.FETCH_ALL_LOV_TYPES_SUCCESSFUL,
      payload: lovs
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_ALL_LOV_TYPES_FAILED, error });
  }
}

function* fetchSuiteTypes(action) {
  const { formatMessage } = action.payload;
  try {
    let suiteTypes = yield call(LovService.fetchSuiteTypes);
    suiteTypes = suiteTypes.map(suiteType => {
      const type = SUITE_TYPE[suiteType.id];
      return {
        id: suiteType.id,
        value: formatMessage({
          id: type.id,
          defaultMessage: type.defaultMessage
        })
      };
    });

    yield put({
      type: lovConstants.FETCH_SUITE_TYPES_SUCCESSFUL,
      payload: suiteTypes
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_SUITE_TYPES_FAILED, error });
  }
}

function* fetchBuildingTypes(action) {
  const { formatMessage } = action.payload;
  try {
    let buildingTypes = yield call(LovService.fetchBuildingTypes);
    buildingTypes = buildingTypes.map(buildingType => {
      const type = BUILDING_TYPE[buildingType.id];
      return {
        id: buildingType.id,
        value: formatMessage({
          id: type.id,
          defaultMessage: type.defaultMessage
        })
      };
    });

    yield put({
      type: lovConstants.FETCH_BUILDING_TYPES_SUCCESSFUL,
      payload: buildingTypes
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_BUILDING_TYPES_FAILED, error });
  }
}

function* fetchPubStatuses(action) {
  const { formatMessage } = action.payload;
  try {
    let statuses = yield call(LovService.fetchPubStatuses);
    statuses = statuses.map(status => {
      const type = PUB_STATUS[status.id];
      return {
        id: status.id,
        value: formatMessage({
          id: type.id,
          defaultMessage: type.defaultMessage
        })
      };
    });

    yield put({
      type: lovConstants.FETCH_PUB_STATUSES_SUCCESSFUL,
      payload: statuses
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_PUB_STATUSES_FAILED, error });
  }
}

function* fetchFavStatuses(action) {
  const { formatMessage } = action.payload;
  try {
    let statuses = yield call(LovService.fetchFavStatuses);
    statuses = statuses.map(status => {
      const type = FAV_STATUS[status.id];
      return {
        id: status.id,
        value: formatMessage({
          id: type.id,
          defaultMessage: type.defaultMessage
        })
      };
    });

    yield put({
      type: lovConstants.FETCH_FAV_STATUSES_SUCCESSFUL,
      payload: statuses
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_FAV_STATUSES_FAILED, error });
  }
}

function* fetchArchivedStatuses(action) {
  const { formatMessage } = action.payload;
  try {
    let statuses = yield call(LovService.fetchArchivedStatuses);
    statuses = statuses.map(status => {
      const type = ARCHIVED_STATUS[status.id];
      return {
        id: status.id,
        value: formatMessage({
          id: type.id,
          defaultMessage: type.defaultMessage
        })
      };
    });

    yield put({
      type: lovConstants.FETCH_ARCHIVED_STATUSES_SUCCESSFUL,
      payload: statuses
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_ARCHIVED_STATUSES_FAILED, error });
  }
}

function* fetchTenantRights(action) {
  const { formatMessage } = action.payload;
  try {
    let statuses = yield call(LovService.fetchTenantRights);
    statuses = statuses.map(status => {
      const type = TENANT_RIGHTS[status.id];
      return {
        id: status.id,
        value: formatMessage({
          id: type.id,
          defaultMessage: type.defaultMessage
        }),
        code: status.code
      };
    });

    yield put({
      type: lovConstants.FETCH_TENANT_RIGHTS_SUCCESSFUL,
      payload: statuses
    });
  } catch (error) {
    yield put({ type: lovConstants.FETCH_TENANT_RIGHTS_FAILED, error });
  }
}

export function* fetchAllLOVTypesWatcher() {
  yield takeEvery(lovConstants.FETCH_ALL_LOV_TYPES, fetchAllLOVTypes);
}

export function* fetchSuiteTypesWatcher() {
  yield takeEvery(lovConstants.FETCH_SUITE_TYPES, fetchSuiteTypes);
}

export function* fetchBuildingTypesWatcher() {
  yield takeEvery(lovConstants.FETCH_BUILDING_TYPES, fetchBuildingTypes);
}

export function* fetchPubStatusesWatcher() {
  yield takeEvery(lovConstants.FETCH_PUB_STATUSES, fetchPubStatuses);
}

export function* fetchFavStatusesWatcher() {
  yield takeEvery(lovConstants.FETCH_FAV_STATUSES, fetchFavStatuses);
}

export function* fetchArchivedStatusesWatcher() {
  yield takeEvery(lovConstants.FETCH_ARCHIVED_STATUSES, fetchArchivedStatuses);
}

export function* fetchTenantRightsWatcher() {
  yield takeEvery(lovConstants.FETCH_TENANT_RIGHTS, fetchTenantRights);
}
