import * as buildingContactConstants from "../constants/buildingContactConstants";

export function fetchBuildingContacts(payload) {
  return {
    type: buildingContactConstants.FETCH_BUILDING_CONTACTS,
    payload
  };
}

export function fetchAllContacts(payload) {
  return {
    type: buildingContactConstants.FETCH_ALL_CONTACTS,
    payload
  };
}

export function updateBuildingContact(payload) {
  return {
    type: buildingContactConstants.UPDATE_BUILDING_CONTACT,
    payload
  };
}

export function deleteBuildingContact(payload) {
  return {
    type: buildingContactConstants.DELETE_BUILDING_CONTACT,
    payload
  };
}

export function addBuildingContacts(payload){
  return {
    type: buildingContactConstants.ADD_BUILDING_CONTACTS,
    payload
  }
}

export function updateBuildingContactsOrder(payload) {
  return {
    type: buildingContactConstants.UPDATE_CONTACT_ORDER,
    payload
  };
}
