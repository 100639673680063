import * as rentRollConstants from "../constants/rentRollConstants";
import * as lovConstants from "../constants/lovConstants";
import * as buildingsConstants from "../constants/buildings";

import update from "immutability-helper";

const initialState = {
  fetching: false,
  meta_data: {
    area_max: null,
    area_min: null,
    TotalRentableArea: "",
    TotalRentableCount: "",
    TotalRentablePercentage: 100,
    TotalAvailableArea: "",
    TotalAvailableCount: "",
    TotalAvailablePercentage: 0,
    TotalNotAvailableArea: "",
    TotalNotAvailableCount: "",
    TotalNotAvailablePercentage: 0,
    TotalExpiresInLessThanOneYearArea: "",
    TotalExpiresInLessThanOneYearCount: "",
    TotalExpiresInLessThanOneYearPercentage: 0,
    TotalMonthToMonthArea: "",
    TotalMonthToMonthCount: "",
    TotalMonthToMonthPercentage: 0,
    TotalCommittedArea: "",
    TotalCommittedCount: "",
    TotalCommittedPercentage: 0
  },
  buckets: [],

  filters: {
    pubStatus: [],
    area_max: null,
    area_min: null
  },
  selectedFilters: {
    pubStatus: [],
    area_max: null,
    area_min: null
  },
  staging: {
    pubStatus: [],
    area_max: null,
    area_min: null
  },
  isFiltersApplied: false
};

const bucketColors = [
  { background: "#aeb1c4", color: "#fff" },
  { background: "#794766", color: "#fff" },
  { background: "#ac4164", color: "#fff" },
  { background: "#cc5262", color: "#fff" },
  { background: "#d16a42", color: "#fff" },
  { background: "#d7903e", color: "#fff" },
  { background: "#5e9d71", color: "#fff" },
  { background: "#458b8c", color: "#fff" },
  { background: "#4b5185", color: "#fff" },
  { background: "#674083", color: "#fff" }
];

function getColorForBucket(index) {
  return bucketColors[index];
}

const reducer = function rentRollReducer(state = initialState, action) {
  switch (action.type) {
    case rentRollConstants.FETCH_RENT_ROLL:
      return Object.assign({}, state, {
        fetching: true
      });

    case rentRollConstants.FETCH_RENT_ROLL_SUCCESSFUL: {
      const fetched_area_min = action.payload.meta_data.area_min;
      const fetched_area_max = action.payload.meta_data.area_max;

      let buckets = action.payload.buckets.map((bucket, i) => {
        return {
          ...getColorForBucket(i),
          collapsed: false,
          ...bucket
        };
      });

      let selected_area_min = state.isFiltersApplied
        ? state.selectedFilters.area_min
        : fetched_area_min;
      let selected_area_max = state.isFiltersApplied
        ? state.selectedFilters.area_max
        : fetched_area_max;

      return update(state, {
        fetching: { $set: false },
        meta_data: { $set: action.payload.meta_data },
        buckets: { $set: buckets },
        filters: {
          area_min: { $set: fetched_area_min },
          area_max: { $set: fetched_area_max }
        },
        selectedFilters: {
          area_min: { $set: selected_area_min },
          area_max: { $set: selected_area_max }
        },
        staging: {
          area_min: { $set: selected_area_min },
          area_max: { $set: selected_area_max }
        }
      });
    }

    case rentRollConstants.FETCH_RENT_ROLL_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });

    case rentRollConstants.TOGGLE_BUCKET: {
      let buckets = state.buckets;
      buckets[action.payload.bucketIndex].collapsed = !buckets[
        action.payload.bucketIndex
      ].collapsed;
      return Object.assign({}, state, {
        buckets: [...buckets]
      });
    }

    case lovConstants.FETCH_PUB_STATUSES_SUCCESSFUL: {
      const pubStatus = action.payload;

      const selectedPubStatus = state.selectedFilters.pubStatus.length
        ? state.selectedFilters.pubStatus
        : pubStatus.map(status => status.id);

      return update(state, {
        filters: {
          pubStatus: { $set: pubStatus }
        },
        selectedFilters: {
          pubStatus: { $set: [...selectedPubStatus] }
        },
        staging: {
          pubStatus: { $set: [...selectedPubStatus] }
        }
      });
    }

    case rentRollConstants.TOGGLE_FILTER_ITEM: {
      const type = action.payload.type;
      let selectedTypeList = [...state.staging[type]];
      const id = action.payload.id;

      if (id === "-1") {
        selectedTypeList =
          selectedTypeList.length === state.filters[type].length
            ? []
            : state.filters[type].map(item => item.id);
      } else {
        if (selectedTypeList.includes(id)) {
          selectedTypeList = selectedTypeList.filter(item => item !== id);
        } else {
          selectedTypeList.push(id);
        }
      }

      return update(state, {
        staging: {
          [type]: { $set: selectedTypeList }
        }
      });
    }

    case rentRollConstants.AREA_SLIDER_CHANGE:
      return update(state, {
        staging: {
          area_min: { $set: action.payload.range[0] },
          area_max: { $set: action.payload.range[1] }
        }
      });

    case rentRollConstants.SAVE_FILTERS:
      return update(state, {
        selectedFilters: {
          pubStatus: { $set: [...state.staging.pubStatus] },
          area_max: { $set: state.staging.area_max },
          area_min: { $set: state.staging.area_min }
        },
        isFiltersApplied: { $set: true }
      });

    case rentRollConstants.RESET_FILTERS: {
      const selectedPubStatus = state.filters.pubStatus.map(
        status => status.id
      );
      const selectedAreaMax = state.filters.area_max;
      const selectedAreaMin = state.filters.area_min;

      return update(state, {
        isFiltersApplied: { $set: false },
        selectedFilters: {
          $set: {
            pubStatus: [...selectedPubStatus],
            area_max: selectedAreaMax,
            area_min: selectedAreaMin
          }
        },
        staging: {
          $set: {
            pubStatus: [...selectedPubStatus],
            area_max: selectedAreaMax,
            area_min: selectedAreaMin
          }
        }
      });
    }

    case buildingsConstants.UNMOUNT_BUILDING_VIEW:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
