import * as actionTypes from "./constants";
import update from "immutability-helper";

const initialState = {
  details: {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    language: "",
    title: "",
    username: "",
    updated: ""
  },
  fetching: false,
  saving: false,

  passwordChangeSuccessful: true,

  userListPage: {
    results: []
  },

  selectedUser: {
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: ""
  },

  userBuildings: {
    results: [],
    next: null,
    previous: null
  },

  // All buildings list for new user creation - Permitted Buildings selection
  newUserAllBuildingsPage: {
    results: []
  }
};

const reducer = function login(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER:
      return Object.assign({}, state, {
        fetching: true
      });

    case actionTypes.FETCH_USER_SUCCESSFUL:
      return Object.assign({}, state, {
        details: action.payload,
        fetching: false
      });

    case actionTypes.FETCH_USER_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });

    // Edit user reducers

    case actionTypes.EDIT_USER:
      return Object.assign({}, state, {
        saving: true
      });

    case actionTypes.EDIT_USER_SUCCESSFUL:
      return update(state, {
        details: {
          username: { $set: action.payload.username },
          first_name: { $set: action.payload.first_name },
          last_name: { $set: action.payload.last_name },
          phone: { $set: action.payload.phone },
          updated: { $set: action.payload.updated },
          language: { $set: action.payload.language },
          title: { $set: action.payload.title }
        },
        saving: { $set: false }
      });

    case actionTypes.EDIT_USER_FAILED:
      return Object.assign({}, state, {
        saving: false
      });

    // Change Password Reducers

    case actionTypes.CHANGE_PASSWORD_SUCCESSFUL:
      return Object.assign({}, state, {
        passwordChangeSuccessful: true
      });

    case actionTypes.CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        passwordChangeSuccessful: false
      });

    case actionTypes.FETCH_USERS:
      return Object.assign({}, state, {
        fetching: true
      });

    case actionTypes.FETCH_USERS_SUCCESSFUL:
      return update(state, {
        userListPage: { $set: action.payload },
        fetching: { $set: false }
      });

    case actionTypes.FETCH_USERS_FAILED:
      return update(state, {
        fetching: { $set: false }
      });

    case actionTypes.FETCH_USER_DETAILS_SUCCESSFUL:
      return update(state, {
        selectedUser: { $set: action.payload }
      });

    case actionTypes.FETCH_USER_BUILDINGS_SUCCESSFUL:
      return update(state, {
        userBuildings: { $set: action.payload }
      });

    // New User Creation - Permitted Buildings
    case actionTypes.FETCH_ALL_BUILDINGS_SUCCESSFUL:
      return update(state, {
        newUserAllBuildingsPage: { $set: action.payload }
      });

    case actionTypes.CREATE_USER:
      return Object.assign({}, state, {
        createUserFailed: null,
        saving: true
      });

    case actionTypes.CREATE_USER_FAILED:
      return Object.assign({}, state, {
        createUserFailed: action.error,
        saving: false
      });

    case actionTypes.CREATE_USER_SUCCESSFUL:
      return Object.assign({}, state, {
        createUserFailed: null,
        saving: false
      });

    default:
      return state;
  }
};

export default reducer;
