import * as floorConstants from "../constants/floorConstants";
import { ObjectUnsubscribedError } from "rxjs";

const initialState = {
  loading: false,
  isSaving: false,
  error: null,
  floorplan: {
    floorplanlease_set: null,
    imageDataURL: null,
    svgText: null
  }
};

const reducer = function floorPlanReducer(state = initialState, action) {
  switch (action.type) {
    case floorConstants.FETCH_FLOOR:
      return Object.assign({}, state, {
        loading: true
      });
    case floorConstants.FETCH_FLOOR_SUCCESSFUL:
      return Object.assign({}, state, {
        loading: false,
        floorPlanImageURL: action.payload.image,
        floorPlanSvgURL: action.payload.svg
      });
    case floorConstants.FETCH_FLOOR_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    case floorConstants.UPDATE_FLOORPLAN_ASSET:
      return Object.assign({}, state, {
        loading: true,
        isSaving: true
      });
    case floorConstants.UPDATE_FLOORPLAN_ASSET_SUCCESSFUL:
      return Object.assign({}, state, {
        loading: false,
        isSaving: false
      });
    case floorConstants.UPDATE_FLOORPLAN_ASSET_FAILED:
      return Object.assign({}, state, {
        loading: false,
        isSaving: false,
        error: action.error
      });

    case floorConstants.CREATE_FLOORPLAN_ASSET:
      return Object.assign({}, state, {
        loading: true,
        isSaving: true
      });
    case floorConstants.CREATE_FLOORPLAN_ASSET_SUCCESSFUL:
      return Object.assign({}, state, {
        loading: false,
        floorplan: {
          ...state.floorplan,
          id: action.payload.id
        },
        isSaving: false
      });
    case floorConstants.CREATE_FLOORPLAN_ASSET_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    case floorConstants.FETCH_FLOORPLAN:
      return Object.assign({}, state, {
        loading: true
      });
    case floorConstants.CLEAR_FLOORPLAN:
      return initialState;
    case floorConstants.FETCH_FLOORPLAN_SUCCESSFUL:
      return Object.assign({}, state, {
        floorplan: action.payload,
        loading: false
      });
    case floorConstants.UPDATE_SVG_TEXT:
      return Object.assign({}, state, {
        floorplan: { ...state.floorplan, svgText: action.payload.svgText }
      });
    case floorConstants.UPDATE_IMAGE_URL:
      return Object.assign({}, state, {
        floorplan: {
          ...state.floorplan,
          imageDataURL: action.payload.imageDataURL
        }
      });
    default:
      return state;
  }
};

export default reducer;
