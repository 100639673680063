import React from "react";
import Spinner from "react-spinkit";

const AppLoader = props => (
  <div
    className={`app-container container-fluid no-padding ${
      props.className ? props.className : ""
    }`}
  >
    <div className="loader-container row">
      <Spinner name="line-scale-pulse-out" color="#fff" />
    </div>
  </div>
);
export const RouteLoader = props => (
  <div
    className={`route-loader container-fluid ${
      props.className ? props.className : ""
    }`}
  >
    <div className="loader-container row">
      <Spinner name="line-scale-pulse-out" color={props.color || "#39aabb"} />
    </div>
  </div>
);

export const Loader = props => (
  <div
    className={`generic-loader container-fluid ${
      props.className ? props.className : ""
    }`}
  >
    <div className="loader-container row">
      <Spinner name="line-scale-pulse-out" color={props.color || "#39aabb"} />
    </div>
  </div>
);

export default AppLoader;
