import { takeEvery, takeLatest, call, put } from "redux-saga/effects";

import * as floorConstants from "../constants/floorConstants";
import * as stackingPlanConstants from "../constants/stackingPlanConstants";
import FloorService from "../services/floorService";
import config from "../auth/http";

function* fetchFloor(action) {
  try {
    const result = yield call(
      FloorService.fetchFloorPlanByFloorId,
      action.payload
    );
    yield put({
      type: floorConstants.FETCH_FLOOR_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: floorConstants.FETCH_FLOOR_FAILED, error });
  }
}

function* updateFloorplanAsset(action) {
  try {
    const result = yield call(
      FloorService.updateFloorplanAsset,
      action.payload
    );
    yield put({
      type: floorConstants.UPDATE_FLOORPLAN_ASSET_SUCCESSFUL,
      payload: result
    });
    action.payload.onSuccess(action.payload.floorplanId);
  } catch (error) {
    yield put({ type: floorConstants.UPDATE_FLOORPLAN_ASSET_FAILED, error });
  }
}

function* createFloorplanAsset(action) {
  try {
    let result = yield call(FloorService.createFloorplanAsset, action.payload);
    result.imageDataURL = result.image_file;
    delete result.image_file;
    yield put({
      type: floorConstants.CREATE_FLOORPLAN_ASSET_SUCCESSFUL,
      payload: result
    });
    action.payload.onSuccess(result.id);
  } catch (error) {
    yield put({ type: floorConstants.CREATE_FLOORPLAN_ASSET_FAILED, error });
  }
}

function* reorderSuites(action) {
  try {
    const result = yield call(FloorService.reorderSuites, action.payload);
    yield put({
      type: floorConstants.REORDER_SUITES_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: floorConstants.REORDER_SUITES_FAILED, error });
  } finally {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  }
}

function* addFloorAbove(action) {
  try {
    const result = yield call(FloorService.addFloorAbove, action.payload);
    yield put({
      type: floorConstants.ADD_FLOOR_ABOVE_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: floorConstants.ADD_FLOOR_ABOVE_FAILED, error });
  } finally {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  }
}

function* addFloorBelow(action) {
  try {
    const result = yield call(FloorService.addFloorBelow, action.payload);
    yield put({
      type: floorConstants.ADD_FLOOR_BELOW_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: floorConstants.ADD_FLOOR_BELOW_FAILED, error });
  } finally {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  }
}

function* deleteFloor(action) {
  try {
    const result = yield call(FloorService.deleteFloor, action.payload);
    yield put({
      type: floorConstants.DELETE_FLOOR_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: floorConstants.DELETE_FLOOR_FAILED, error });
  } finally {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  }
}

function* updateFloor(action) {
  try {
    const result = yield call(FloorService.updateFloor, action.payload);
    yield put({
      type: floorConstants.UPDATE_FLOOR_SUCCESSFUL,
      payload: result
    });
  } catch (error) {
    yield put({ type: floorConstants.UPDATE_FLOOR_FAILED, error });
  } finally {
    yield put({
      type: stackingPlanConstants.FETCH_STACKING_PLAN,
      payload: {
        buildingId: action.payload.buildingId
      }
    });
  }
}

function* fetchFloorPlan(action) {
  const { floorPlanId } = action.payload;
  try {
    const result = yield call(FloorService.fetchFloorPlan, floorPlanId);
    let payload = {};
    if (!result.svg_file) {
      payload = {
        ...result,
        imageDataURL: result.image_file
      };
    } else {
      const svgText = yield call(FloorService.getSvgAsset, result.svg_file);
      payload = {
        floorplanlease_set: result.floorplanlease_set,
        imageDataURL: result.image_file,
        svgText,
        id: result.id
      };
    }
    yield put({
      type: floorConstants.FETCH_FLOORPLAN_SUCCESSFUL,
      payload
    });
  } catch (error) {
    yield put({
      type: floorConstants.FETCH_FLOORPLAN_FAILED,
      error
    });
  }
}

export function* reorderSuitesWatcher() {
  yield takeEvery(floorConstants.REORDER_SUITES, reorderSuites);
}

export function* addFloorAboveWatcher() {
  yield takeEvery(floorConstants.ADD_FLOOR_ABOVE, addFloorAbove);
}

export function* addFloorBelowWatcher() {
  yield takeEvery(floorConstants.ADD_FLOOR_BELOW, addFloorBelow);
}

export function* deleteFloorWatcher() {
  yield takeEvery(floorConstants.DELETE_FLOOR, deleteFloor);
}

export function* updateFloorWatcher() {
  yield takeEvery(floorConstants.UPDATE_FLOOR, updateFloor);
}

export function* fetchFloorWatcher() {
  yield takeEvery(floorConstants.FETCH_FLOOR, fetchFloor);
}

export function* updateFloorplanAssetWatcher() {
  yield takeLatest(floorConstants.UPDATE_FLOORPLAN_ASSET, updateFloorplanAsset);
}

export function* createFloorplanAssetWatcher() {
  yield takeLatest(floorConstants.CREATE_FLOORPLAN_ASSET, createFloorplanAsset);
}

export function* fetchFloorPlanWatcher() {
  yield takeEvery(floorConstants.FETCH_FLOORPLAN, fetchFloorPlan);
}
