import { call, all } from "redux-saga/effects";

import {
  verifyTokenWatcher,
  tokenVerificationFailedWatcher,
  refreshTokenWatcher,
  authenticateUserWatcher,
  logoutWatcher,
  forgotPasswordWatcher,
  validateResetPasswordKeyWatcher,
  resetPasswordWatcher,
  forcePasswordChangeWatcher
} from "./containers/Auth/sagas";

import { checkConnectivityWatcher } from "./sagas/networkSaga";

import { searchAppWatcher } from "./sagas/searchSaga";
import { systemSettingsWatcher } from "./sagas/settingSaga";

import {
  fetchUserWatcher,
  createUserWatcher,
  editUserWatcher,
  deleteUserWatcher,
  changePasswordWatcher,
  fetchUsersWatcher,
  fetchUserDetailsWatcher,
  editUserDetailsWatcher,
  fetchUserBuildingsWatcher,
  deleteUserBuildingWatcher,
  editUserRoleTypeWatcher,
  fetchAllBuildingsWatcher,
  transferBuildingAssignmentsWatcher,
  copyBuildingAssignmentsWatcher,
  addUserBuildingsWatcher
} from "./containers/User/sagas";

import {
  fetchDashboardMetaWatcher,
  fetchDashboardFilterItemsWatcher,
  fetchDistributionWatcher,
  fetchLeaseExpiryProfileWatcher
} from "./sagas/dashboardSaga";

import {
  fetchBuildingWatcher,
  fetchBuildingAfterUpdateWatcher,
  fetchBuildingsWatcher,
  fetchBuildingsFilterItemsWatcher,
  createBuildingWatcher,
  deleteBuildingWatcher,
  updateBuildingWatcher,
  setFavouriteWatcher,
  unsetFavouriteWatcher,
  fetchBuildingGroupsWatcher,
  fetchPromotedBuildingsWatcher,
  promoteBuildingWatcher,
  unPromoteBuildingWatcher,
  updatePromotedBuildingListWatcher,
  archiveBuildingWatcher,
  unArchiveBuildingWatcher,
  fetchBuildingSuitesWatcher
} from "./sagas/buildingsSaga";

import {
  fetchExtendedBuildingWatcher,
  updateExtendedBuildingWatcher,
  autoComputeValuesWatcher,
  calculateAdditionalRentWatcher
} from "./sagas/extendedBuildingSaga";

import {
  fetchSuitesWatcher,
  updateSuiteWatcher,
  deleteSuiteWatcher,
  fetchSuitesFilterItemsWatcher,
  splitSuiteWatcher,
  addSuiteLeftWatcher,
  addSuiteRightWatcher,
  addNewSuiteWatcher,
  fetchStackingSuiteDetailsWatcher
} from "./sagas/suitesSaga";

import { updateExtendedSuiteInStackingPlanWatcher } from "./sagas/extendedSuiteSaga";

import {
  fetchLeasesWatcher,
  updateLeaseWatcher,
  createLeaseWatcher,
  deleteLeaseWatcher
} from "./sagas/leaseSaga";

import { fetchRentRollWatcher } from "./sagas/rentRollSaga";

import {
  fetchOccupancyReportWatcher,
  fetchDistributionReportWatcher,
  fetchLeaseExpiryProfileReportWatcher,
  fetchLargestTenantReportWatcher,
  fetchVacantSuitesReportWatcher
} from "./sagas/buildingReportsSaga";

import {
  fetchStackingPlanWatcher,
  fetchStackingPlanFilterProfileWatcher
} from "./sagas/stackingPlanSaga";

import {
  fetchSuiteMediaWatcher,
  uploadSuiteMediaWatcher,
  deleteSuiteMediaWatcher,
  updateSuiteMediaWatcher,
  setSuitePrimaryPictureWatcher,
  toggleSuitePublishMediaStatusWatcher,
  reorderSuiteMediaWatcher
} from "./sagas/suiteMediaSaga";

import {
  fetchAllLOVTypesWatcher,
  fetchSuiteTypesWatcher,
  fetchBuildingTypesWatcher,
  fetchPubStatusesWatcher,
  fetchFavStatusesWatcher,
  fetchArchivedStatusesWatcher,
  fetchTenantRightsWatcher
} from "./sagas/lovSaga";

import {
  fetchBuildingAnnouncementsWatcher,
  updateBuildingAnnouncementWatcher
} from "./sagas/buildingAnnouncementSaga";

import {
  updateBuildingInBuildingInformationWatcher,
  fetchBuildingInformationWatcher,
  fetchBuildingInformationMetaWatcher,
  createBuildingInformationItemWatcher,
  updateBuildingInformationItemWatcher,
  deleteBuildingInformationItemWatcher,
  reorderBuildingInformaionItemsWatcher
} from "./sagas/buildingInformationSaga";

import {
  fetchBuildingMediaWatcher,
  uploadBuildingMediaWatcher,
  deleteBuildingMediaWatcher,
  setPrimaryPictureWatcher,
  togglePublishMediaStatusWatcher,
  updateBuildingMediaWatcher,
  reorderMediaWatcher
} from "./sagas/buildingMediaSaga";

import {
  reorderSuitesWatcher,
  addFloorAboveWatcher,
  addFloorBelowWatcher,
  deleteFloorWatcher,
  updateFloorWatcher,
  updateFloorplanAssetWatcher,
  createFloorplanAssetWatcher,
  fetchFloorPlanWatcher
} from "./sagas/floorSaga";

import {
  fetchBuildingContactsWatcher,
  fetchAllContactsWatcher,
  updateBuildingContactWatcher,
  deleteBuildingContactWatcher,
  addBuildingContactsWatcher,
  updateBuildingContactsOrderWatcher
} from "./sagas/buildingContactSaga";
import { addBuildingContacts } from "./actions/buildingContactActions";

export default function* rootSaga() {
  yield all([
    call(authenticateUserWatcher),
    call(verifyTokenWatcher),
    call(tokenVerificationFailedWatcher),
    call(refreshTokenWatcher),
    call(logoutWatcher),
    call(forgotPasswordWatcher),
    call(validateResetPasswordKeyWatcher),
    call(resetPasswordWatcher),
    call(forcePasswordChangeWatcher),

    call(checkConnectivityWatcher),

    call(searchAppWatcher),

    call(systemSettingsWatcher),

    call(fetchUserWatcher),
    call(createUserWatcher),
    call(editUserWatcher),
    call(deleteUserWatcher),
    call(changePasswordWatcher),
    call(fetchUsersWatcher),
    call(fetchUserDetailsWatcher),
    call(editUserDetailsWatcher),
    call(fetchUserBuildingsWatcher),
    call(deleteUserBuildingWatcher),
    call(editUserRoleTypeWatcher),
    call(fetchAllBuildingsWatcher),
    call(transferBuildingAssignmentsWatcher),
    call(copyBuildingAssignmentsWatcher),
    call(addUserBuildingsWatcher),

    call(fetchDashboardMetaWatcher),
    call(fetchDashboardFilterItemsWatcher),
    call(fetchDistributionWatcher),
    call(fetchLeaseExpiryProfileWatcher),

    call(fetchBuildingWatcher),
    call(fetchBuildingAfterUpdateWatcher),
    call(fetchBuildingsWatcher),
    call(fetchBuildingsFilterItemsWatcher),
    call(createBuildingWatcher),
    call(deleteBuildingWatcher),
    call(updateBuildingWatcher),
    call(setFavouriteWatcher),
    call(unsetFavouriteWatcher),
    call(fetchBuildingGroupsWatcher),
    call(fetchPromotedBuildingsWatcher),
    call(promoteBuildingWatcher),
    call(unPromoteBuildingWatcher),
    call(updatePromotedBuildingListWatcher),
    call(archiveBuildingWatcher),
    call(unArchiveBuildingWatcher),
    call(fetchBuildingSuitesWatcher),

    call(fetchExtendedBuildingWatcher),
    call(updateExtendedBuildingWatcher),
    call(autoComputeValuesWatcher),
    call(calculateAdditionalRentWatcher),

    call(fetchSuitesWatcher),
    call(updateSuiteWatcher),
    call(deleteSuiteWatcher),
    call(fetchSuitesFilterItemsWatcher),
    call(splitSuiteWatcher),
    call(addSuiteLeftWatcher),
    call(addSuiteRightWatcher),
    call(addNewSuiteWatcher),
    call(fetchStackingSuiteDetailsWatcher),

    call(updateExtendedSuiteInStackingPlanWatcher),

    call(fetchLeasesWatcher),
    call(updateLeaseWatcher),
    call(createLeaseWatcher),
    call(deleteLeaseWatcher),

    call(fetchRentRollWatcher),

    call(fetchOccupancyReportWatcher),
    call(fetchDistributionReportWatcher),
    call(fetchLeaseExpiryProfileReportWatcher),
    call(fetchLargestTenantReportWatcher),
    call(fetchVacantSuitesReportWatcher),

    call(fetchStackingPlanWatcher),
    call(fetchStackingPlanFilterProfileWatcher),

    call(fetchSuiteMediaWatcher),
    call(uploadSuiteMediaWatcher),
    call(deleteSuiteMediaWatcher),
    call(updateSuiteMediaWatcher),
    call(setSuitePrimaryPictureWatcher),
    call(toggleSuitePublishMediaStatusWatcher),
    call(reorderSuiteMediaWatcher),

    call(fetchAllLOVTypesWatcher),
    call(fetchSuiteTypesWatcher),
    call(fetchBuildingTypesWatcher),
    call(fetchPubStatusesWatcher),
    call(fetchFavStatusesWatcher),
    call(fetchArchivedStatusesWatcher),
    call(fetchTenantRightsWatcher),
    call(fetchBuildingAnnouncementsWatcher),
    call(updateBuildingAnnouncementWatcher),
    call(updateBuildingInBuildingInformationWatcher),
    call(fetchBuildingInformationWatcher),
    call(fetchBuildingInformationMetaWatcher),
    call(createBuildingInformationItemWatcher),
    call(updateBuildingInformationItemWatcher),
    call(deleteBuildingInformationItemWatcher),
    call(reorderBuildingInformaionItemsWatcher),
    call(fetchBuildingMediaWatcher),
    call(uploadBuildingMediaWatcher),
    call(deleteBuildingMediaWatcher),
    call(setPrimaryPictureWatcher),
    call(togglePublishMediaStatusWatcher),
    call(updateBuildingMediaWatcher),
    call(reorderMediaWatcher),
    call(fetchFloorPlanWatcher),
    call(reorderSuitesWatcher),
    call(addFloorAboveWatcher),
    call(addFloorBelowWatcher),
    call(deleteFloorWatcher),
    call(updateFloorWatcher),
    call(updateFloorplanAssetWatcher),
    call(createFloorplanAssetWatcher),
    call(deleteBuildingContactWatcher),
    call(addBuildingContactsWatcher),
    call(updateBuildingContactsOrderWatcher),
    call(fetchBuildingContactsWatcher),
    call(fetchAllContactsWatcher),
    call(updateBuildingContactWatcher)
  ]);
}
